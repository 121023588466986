import { CButton, CCard, CCardBody, CImg, CRow } from '@coreui/react'
import React, { Fragment } from 'react'
import coopsyslogo from "../../../img/CoopSysLogo.png";
import { useDispatch } from 'react-redux';
import { Terminos } from '../login/Terminos';

export const Page401Out = ({data}) => {
    const dispatch = useDispatch();

  return (
    <Fragment>
      <div className="main-section-blanco-container">
        <section className="main-section-blanco" id="logins">
          <div className="flex flex-column align-center justify-start login">
            
              <CRow
                className="flex flex-row justify-between align-center"
                style={{
                  width: "100%",
                  height: "fit-content",
                  padding: "20px 30px",
                }}
              >
                
                <p className="text-center textbrand mt-2">
                  <span className="textbrand me-25">
                    ¿Necesitas Ayuda?
                  </span>
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a href="https://www.dasoft.com.do/contactenos/">
                      <span>Contactar a Soporte</span>
                    </a>
                  }
                </p>

                <p className="text-center textbrand mt-2">
                  <span className="me-25">¿Necesitas una Cotizacion?</span>
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a href="#/auth/cotiza">
                      <span>Cotiza aqui !</span>
                    </a>
                  }
                </p>
              </CRow>
            
            <div className="flex flex-column align-center login-section">
              <div className="login-wrapper">
                <CCard
                  style={{
                    borderRadius: 30,
                  }}
                >
                  <CCardBody>
                    <CImg
                      id="logo"
                      src={coopsyslogo
                      }
                    />
                    {/* <h1
                      style={{
                        color: "rgb(15, 23, 42)",
                        fontSize: "24px",
                        fontWeight: 600,
                        lineHeight: "32px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      Ingresa a tu cuenta
                    </h1> */}
                    {/* <p className="mb-4 card-text">
                      Inicia sesión en tu cuenta para acceder a la plataforma.
                    </p> */}
                     
                      
                      <div className="form-group">
                        {data.alert}
                      </div>
                      <div
                        className="row justify-content-center my-3 px-3"
                        style={{ flexFlow: "row" }}
                      >
                        {" "}
                        <CButton
                          type="submit"
                          color="primary"
                          className="btn-block LoginInput2"
                          href={data.redirect}
                        >
                          ir a coopsys.app
                        </CButton>
                      </div>
                       
                    
                    <div className="row justify-content-center my-2 mt-4">
                      
                      <CButton
                        onClick={() =>
                          dispatch({
                            type: "POLICITA_PRIVACIDAD",
                            payload: true,
                          })
                        }
                      >
                        <small className="text-muted">
                          Politica de Privacidad
                        </small>
                      </CButton>
                      <CButton
                        href="https://dasoftsrl.atlassian.net/servicedesk/customer/portals"
                        target="_banck"
                      >
                        <small className="text-muted"> Ayuda !</small>
                      </CButton>
                    </div>
                  </CCardBody>
                </CCard>
              </div>
            </div>
          </div>
        </section>
      </div>
        
      {/* <Terminos /> */}
    </Fragment>
  )
}
