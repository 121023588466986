import {
  CButton,
  CCol,
  CImg,
  
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ilustracion from "../img/illustration-9.png";
import gauth from "../img/google-authenticator-code.png";
import NumberFormat from "react-number-format";

export const AccesoNoAutorizado = ({GAuth}) => {
  const [gAuth, setgAuth] = useState("");
  const dispatch = useDispatch();
  const { mensaje } = useSelector((state) => state.usuario);

  useEffect(()=>{
    if(gAuth.replace("_", "").length===7){
      GAuth(gAuth);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[gAuth])
   return (
    <div>
      {mensaje.gAuth ? (
        <CModal
          show={mensaje.show}
          onClose={() => dispatch({ type: "CLEAR_SESION_ERROR" })}
          closeOnBackdrop={false}
          centered
          style={{ background: "#f6f6f6" }}
          // size=""
        >
          <Fragment>
            <CModalBody>
              <CCol xs="12">
                <div className="pricing-free-trial mt-5">
                  <div
                    className="row justify-content-center px-3 mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <CImg width={350} src={gauth} />
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                      <div className="pricing-trial-content d-flex justify-content-center">
                        <div className="text-md-left ustify-content-center">
                          <h3 className="text-secundary">2FA</h3>
                           
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                      <div className="pricing-trial-content d-flex justify-content-between">
                        <div className="text-md-left ustify-content-center mt-3">
                          {/* <h3 className="text-primary">{mensaje.title}</h3> */}
                          <p style={{ color: "#32325d", textAlign: "center" }}>
                            Abra su aplicación de autenticación e ingrese el
                            código de seguridad de su proveedor
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                      <div className="pricing-trial-content d-flex justify-content-between  mt-3">
                        <NumberFormat
                          className="form-control"
                          value={gAuth}
                          onChange={(e) => setgAuth(e.target.value)}
                          format="### ###"
                          mask="_"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row justify-content-center my-3 px-3">
                      {" "}
                      <CButton
                        color="primary"
                        className="btn-block btn-color"
                        onClick={()=>{
                          GAuth(gAuth);
                        }}
                      >
                        Verificar
                      </CButton>
                    </div>
                  </div>
                </div>
              </CCol>
            </CModalBody>
            <CModalFooter style={{ borderTop: 0 }}>
              <CButton
                onClick={() => {
                  dispatch({ type: "CLEAR_SESION_ERROR" });
                }}
              >
                Cancel
              </CButton>
            </CModalFooter>
          </Fragment>
        </CModal>
      ) : (
        <CModal
          show={mensaje.show}
          onClose={() => dispatch({ type: "CLEAR_SESION_ERROR" })}
          closeOnBackdrop={false}
          centered
          size="lg"
        >
          <Fragment>
            <CModalBody>
              <CCol xs="12">
                <div className="pricing-free-trial mt-5">
                  <div className="row">
                    <div className="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                      <div className="pricing-trial-content d-flex justify-content-between">
                        <div className="text-md-left mt-3">
                          <h3 className="text-primary">{mensaje.title}</h3>
                          <p style={{ color: "#32325d" }}>{mensaje.text}</p>
                        </div>
                        <CImg width={200} src={ilustracion} />
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
            </CModalBody>
            <CModalFooter style={{ borderTop: 0 }}>
              <CButton
                onClick={() => {
                  dispatch({ type: "CLEAR_SESION_ERROR" });
                }}
              >
                Cancel
              </CButton>
            </CModalFooter>
          </Fragment>
        </CModal>
      )}
    </div>
  );
};
