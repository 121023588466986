const initialState = {
  show: false,
  success : false,
  data : []
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SQL-Inject":
      return {
        ...state,
        show: action.payload,
      };
      case "SQL-Inject-Success":
        return {
          ...state,
          success : action.payload.show,
          data: action.payload.data,
        };
    default:
      return state;
  }
}
