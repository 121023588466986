/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, Fragment } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CImg,
  CInput,
  CInputGroup,
  CInputGroupText,
  CLabel,
  CRow,
} from "@coreui/react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  gauthenticator,
  inicioSesionReducer,
} from "../../../store/actions/usuarioAction";

// Otros componentes
import { sha256 } from "js-sha256";
import "../login/login.css";
import coopsyslogo from "../../../img/CoopSysLogo.png";
import { Terminos } from "../login/Terminos";
import ValidarMail from "./ValidarMail";
import Axios2 from "src/config/axios2v";
import { Downloading } from "src/reusable/Spinner";
import swal from "sweetalert";
import Registrado from "./Registrado";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Register = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [Code, setCode] = useState("");
  const [enviando, setenviando] = useState(false);
  const [registrando, setRegistrando] = useState(false);
  const [registrado, setRegistrado] = useState(false);
  // State local
  const [DataForm, setDataForm] = useState({
    cooperativa: "",
    telefono: "",
    direccion: "",
    representante: "",
    mail: "",
    password: "",
    datname: "",
    usuario: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validacion
    if (
      DataForm.mail.trim() === "" ||
      DataForm.password.trim() === "" ||
      DataForm.cooperativa.trim() === "" ||
      DataForm.representante.trim() === ""
    ) {
      swal({
        icon: "warning",
        title: "Campos Requeridos !",
        text: `Tos los campos son necesarios`,
        buttons: "Aceptar",
      });

      return;
    }



    // Enviar al action
    let encryptedPassword = sha256(DataForm.password).toString();

    console.log({
      ...DataForm,
      password: encryptedPassword,
    })

    setenviando(true);
    Axios2.post("/auth/Register/code", {
      ...DataForm,
      password: encryptedPassword,
    })
      .then(({ data }) => {
        setCode(data.code);
        setActive(true);
        setenviando(false);
      })
      .catch((error) => {
        setRegistrando(false);
        setenviando(false);
        swal(
          `Error ${error.response.status.toString()}`,
          `${error.response.data}  `,
          "warning"
        );
      });
  };

  const RegisterSend = async (e) => {
    setActive(false);
    setRegistrando(true);
    let encryptedPassword = sha256(DataForm.password).toString();

    await Axios2.post("/auth/Register", {
      ...DataForm,
      password: encryptedPassword,
    })
      .then(() => {
        setRegistrando(false);
        setRegistrado(true);
      })
      .catch((error) => {
        setRegistrando(false);
        swal(
          `Error ${error.response.status.toString()}`,
          `${error.response.data}  `,
          "warning"
        );
      });
  };
  useEffect(() => {
    const loginElement = document.getElementById("logins");

    const script = document.createElement("script");

    script.src = "https://static.coopsys.app/js/facebook.js";
    script.async = true;

    loginElement.appendChild(script);
  }, []);

  const limpiar = (value) => {
    if (value === null) {
      return "";
    }

    var result = "";
    var characters = "Ñ0123456789!&*()-/ ";

    for (let i = 0; i < value.length; i++) {
      if (!characters.includes(value[i])) {
        result += value[i];
      }
    }

    return result;
  };
  const handleChange = (e) => {
    setDataForm({
      ...DataForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Fragment>
      {registrando && <Downloading value="Registrando" />}
      <div className="main-section-blanco-container">
        <section className="main-section-blanco" id="logins">
          {/* <div className="login-section-banner">
          <CImg
            src="/img/bannerlogin.svg"
            // src="https://nomina.alegra.com/electronic-payroll-assets/img/login-sidebar.de6d3723.svg"
          />
        </div> */}
          <div className="flex flex-column align-center justify-start register">
            <CRow
              className="flex flex-row justify-between align-center"
              style={{
                width: "100%",
                height: "fit-content",
                padding: "20px 30px",
              }}
            >
              <p className="text-center textbrand mt-2">
                <span className="textbrand me-25">¿Ya Estas registrado?</span>
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a href="#/auth/login">
                    <span>Inicia Seccion</span>
                  </a>
                }
              </p>

              <p className="text-center textbrand mt-2">
                <span className="me-25">¿Necesitas una Cotizacion?</span>
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a href="#/auth/cotiza">
                    <span>Cotiza aqui !</span>
                  </a>
                }
              </p>
            </CRow>

            <div className="flex flex-column align-center register-section">
              <div className="register-wrapper">
                <CImg id="logo" src={coopsyslogo} />
                <h1
                  style={{
                    color: "rgb(15, 23, 42)",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "32px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Registrar 
                </h1>
                <p className="mb-4 card-text">
                Formulario de Registro Cooperativa.
                </p>
                <CForm onSubmit={handleSubmit}>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input" className="LoginLabel">
                        Cooperativa
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        className="form-control LoginInput2"
                        name="cooperativa"
                        value={DataForm.cooperativa}
                        placeholder="Nombre Corto / Comercial / @Dominio"
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input" className="LoginLabel">
                        Telefono
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        className="form-control LoginInput2"
                        name="telefono"
                        value={DataForm.telefono}
                        placeholder="Telefono"
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input" className="LoginLabel">
                        Direccion
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        className="form-control LoginInput2"
                        name="direccion"
                        value={DataForm.direccion}
                        placeholder="Direccion"
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input" className="LoginLabel">
                        Representante
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        className="form-control LoginInput2"
                        name="representante"
                        value={DataForm.representante}
                        placeholder="Nombre del representante"
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input" className="LoginLabel">
                        Correo
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        className="form-control LoginInput2"
                        name="mail"
                        value={DataForm.mail}
                        placeholder="Correo"
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input" className="LoginLabel">
                        Contraseña
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        className="form-control LoginInput2"
                        type="password"
                        placeholder="Contraseña"
                        name="password"
                        value={DataForm.password}
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>

                  <div className="row justify-content-center my-3 px-3">
                    {" "}
                    <CButton
                      type="submit"
                      color="primary"
                      className="btn-block LoginInput2"
                      disabled={enviando}
                    >
                      Registrar
                    </CButton>
                  </div>
                </CForm>
                <div className="row justify-content-center my-2 mt-4">
                  <CButton
                    onClick={() =>
                      dispatch({ type: "POLICITA_PRIVACIDAD", payload: true })
                    }
                  >
                    <small className="text-muted">Politica de Privacidad</small>
                  </CButton>
                  <CButton
                    href="https://dasoftsrl.atlassian.net/servicedesk/customer/portals"
                    target="_banck"
                  >
                    <small className="text-muted"> Ayuda !</small>
                  </CButton>
                </div>
              </div>
            </div>
          </div>
          <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat"></div>
        </section>
      </div>

      <ValidarMail
        mail={DataForm.mail}
        code={Code}
        active={active}
        Close={() => setActive(false)}
        Validado={() => {
          RegisterSend(DataForm);
        }}
      />
      <Registrado
        show={registrado}
        DataForm={DataForm}
        Close={() => {
          setRegistrado(false);
          setTimeout(() => history.push("/login"), 400);
        }}
      />
      <Terminos />
    </Fragment>
  );
};

export default Register;
