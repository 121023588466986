const initialState = {
  cargando: true,
  suscripciones: [],
  suscripcion: {
    perfil: {
      cargando: true,
      data: {},
    },
    payment: {
      cargando: true,
      data: {},
    },
    EventMail: {
      cargando: false,
      data: [],
    },
    Documentaciones : {
      cargando: false,
      data: [],
    },
  },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "GET-SUSCRIPCIONES":
      return {
        ...state,
        cargando: action.payload,
      };
    case "GET-SUSCRIPCIONES->Success":
      return {
        ...state,
        cargando: false,
        suscripciones: action.payload,
      };

    case "GET-SUSCRIPCION":
      return {
        ...state,
        suscripcion: {
          ...state.suscripcion,
          perfil: {
            cargando: true,
            data: {},
          },
        },
      };
    case "GET-SUSCRIPCION->Success":
      return {
        ...state,
        suscripcion: {
          ...state.suscripcion,
          perfil: {
            cargando: false,
            data: action.payload,
          },
        },
      };

    case "GET-SUSCRIPCION-PAYMENT":
      return {
        ...state,
        suscripcion: {
          ...state.suscripcion,
          payment: {
            cargando: true,
            data: {},
          },
        },
      };
    case "GET-SUSCRIPCION-PAYMENT->Success":
      return {
        ...state,
        suscripcion: {
          ...state.suscripcion,
          payment: {
            cargando: false,
            data: action.payload,
          },
        },
      };

    case "GET-EVENTMAIL":
      return {
        ...state,
        suscripcion: {
          ...state.suscripcion,
          EventMail: {
            cargando: true,
            data: [],
          },
        },
      };
    case "GET-EVENTMAIL->Success":
      return {
        ...state,
        suscripcion: {
          ...state.suscripcion,
          EventMail: {
            cargando: false,
            data: action.payload,
          },
        },
      };

      case "GET-DOCUMENTACIONES-SHOW":
        return {
          ...state,
          suscripcion: {
            ...state.suscripcion,
            Documentaciones: {
              ...state.suscripcion.Documentaciones,
              show : action.payload,
              customer : action.customer
            },
          },
        };

      case "GET-DOCUMENTACIONES":
        return {
          ...state,
          suscripcion: {
            ...state.suscripcion,
            Documentaciones: {
              ...state.suscripcion.Documentaciones,
              cargando: true,
              data: [],
            },
          },
        };
      case "GET-DOCUMENTACIONES->Success":
        return {
          ...state,
          suscripcion: {
            ...state.suscripcion,
            Documentaciones: {
              ...state.suscripcion.Documentaciones,
              cargando: false,
              data: action.payload,
            },
          },
        };
    default:
      return state;
  }
}
