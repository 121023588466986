import {
  CONSULTA_SOCIO_INICIO,
  CONSULTA_SOCIO_EXITO,
  CONSULTA_SOCIO_ERROR,
  CONSULTA_LISTSOCIO_INICIO,
  CONSULTA_LISTSOCIO_EXITO,
  CONSULTA_LISTSOCIO_ERROR,
  UPDATE_SOCIO_INICIO,
  UPDATE_SOCIO_EXITO,
  UPDATE_SOCIO_ERROR,
  CREAR_CUENTA_INICIO,
  CREAR_CUENTA_EXITO,
  CREAR_CUENTA_ERROR,
  CREAR_CUENTA_LIMPIAR,
  LIST_CUENTA_SOCIO,
  LIST_CUENTA_SOCIO_OK,
  LIST_CUENTA_SOCIO_ERROR
} from "../types/index";

const initialState = {
  cargando: false,
  validacion:false,
  cargandolista: false,
  socio_cargado : false,
  update: false,
  create: false,
  search_estado: false,
  error: "",
  socios: [],
  perfil: [],
  estado: [],
  listCuenta: [],
  require_autorizacion: false,
  require_data: 0,
  calback: ()=>console.log('Calback'),
  mailestado : {
    show : false,
    data : {}
  },
  prestamoprintform : {
    show : false,
    data : {}
  },
  solicitudprintform : {
    show : false,
    data : {}
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {

    case 'SOLICITUD-CREDITO->show' :
      return {
        ...state,
        solicitudprintform : {
          show : true,
          data : action.payload
        }
      };
      case 'SOLICITUD-CREDITO->close' :
        return {
          ...state,
          solicitudprintform : {
            show : false,
            data : {}
          }
        };


    case 'PRESTAMO-FORM->show' :
      return {
        ...state,
        prestamoprintform : {
          show : true,
          data : action.payload
        }
      };
      case 'PRESTAMO-FORM->close' :
        return {
          ...state,
          prestamoprintform : {
            show : false,
            data : {}
          }
        };
    case 'MAIL-ESTADO->close' :
      return {
        ...state,
        mailestado : {
          show : false,
          data : []
        }
      };
    case 'MAIL-ESTADO->show' :
      return {
        ...state,
        mailestado : {
          show : true,
          data : action.payload
        }
      };
    case 'REQUIERE_AUTORIZACION' :
      return {
        ...state,
        cargando: false,
        require_autorizacion : true,
        require_data: action.data,
        calback : action.calback
      };
    case CONSULTA_LISTSOCIO_INICIO:
      return {
        ...state,
        action: action.payload,
        cargandolista: true,
        cargando: false,
        create:false
      };
    case CONSULTA_LISTSOCIO_EXITO:
      return {
        ...state,
        listsocios: action.payload.rows,
        totalRow: action.payload.totalRow,
        totalPages : action.payload.totalPages,
        cargandolista: false,
        cargando: false,
        update: false,
      };
    case CONSULTA_LISTSOCIO_ERROR:
      return {
        ...state,
        error: action.payload,
        cargandolista: false,
        cargando: false,
      };

    case CONSULTA_SOCIO_INICIO:
      return {
        ...state,
        cargando: action.payload,
        socio_cargado : false,
        update: false,
        validacion : false,
      };
    case CONSULTA_SOCIO_EXITO:
      return {
        ...state,
        socios: action.payload,
        socio_cargado : true,
        cargando: false,
      };
    case CONSULTA_SOCIO_ERROR:
      return {
        ...state,
        error: action.payload,
        cargando: false,
      };
    case 'VALIDACION' : 
    
    return {
      ...state,
      validacion: action.payload,
    };
    case UPDATE_SOCIO_INICIO:
      return {
        ...state,
        actualizando: action.payload,
        update: false,
      };
    case UPDATE_SOCIO_EXITO:
      return {
        ...state,
        updatesocios: action.payload,
        actualizando: false,
        update: true,
      };
    case UPDATE_SOCIO_ERROR:
      return {
        ...state,
        error: action.payload,
        actualizando: false,
        update: false,
      };


    case CREAR_CUENTA_INICIO:
      return {
        ...state,
        create: action.payload,
      };
    case CREAR_CUENTA_EXITO:
      return {
        ...state,
        createAccount: action.payload,
        create:true
      };
    case CREAR_CUENTA_ERROR:
      return {
        ...state,
        error: action.payload,
        create: false,
      };

    case CREAR_CUENTA_LIMPIAR :
      return {
        ...state,
        create: false
      };

    //
    case LIST_CUENTA_SOCIO:
      return {
        ...state,
        listando: action.payload,
      };
    case LIST_CUENTA_SOCIO_OK:
      return {
        ...state,
        listCuenta: action.payload,
      };
    case LIST_CUENTA_SOCIO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case 'SEARCH_ESTADO_OPEN' :
      return {
        ...state,
        search_estado: true,
      };
    case 'SEARCH_ESTADO_CLOSE' :
      return {
        ...state,
        search_estado: false,
      }; 
    default:
      return state;
  }
}
