import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const carIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/741/741407.png", // Icono de ejemplo
  iconSize: [20, 20],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

export const Maps = ({position}) => {
  

    // Información del vehículo
    const carInfo = {
      modelo: "Toyota Corolla 2022",
      marca: "Toyota",
      titular: "Juan Pérez",
      imagen: "https://via.placeholder.com/300x150?text=Carro+Toyota", // Reemplaza con una URL real de imagen
    };

  return (
    <MapContainer
      center={position}
      zoom={30}
      style={{ height: "500px", width: "100%" }}
    >
      {/* Capa base del mapa */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* Marcador con popup */}
      <Marker position={position} icon={carIcon}>
       <Popup>
          <div style={{ textAlign: "center", maxWidth: "300px" }}>
            <h4 style={{ margin: "10px 0" }}>{carInfo.marca}</h4>
            <img
              src={carInfo.imagen}
              alt="Carro"
              style={{ width: "100%", borderRadius: "5px", marginBottom: "10px" }}
            />
            <p><strong>Modelo:</strong> {carInfo.modelo}</p>
            <p><strong>Titular:</strong> {carInfo.titular}</p>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};
