const settings = {
  produccion: true,
  lasversion: "2024-08",
};
const config = {
  produccion: {
    test: !settings.produccion,
    v01: `https://api.coopsys.com.do/core/1.0`,
    v02: `https://api.coopsys.com.do/core/2.0`,
    v02Report: `https://api.coopsys.com.do/ReportPDF/`,
  },
  test: {
    test: !settings.produccion,
    v01: `https://api.coopsys.com.do/core/1.0`,
    v02: `https://api.coopsys.com.do/dev/core`,
    v02Report: `https://api.coopsys.com.do/ReportPDF/`,
  },
};

module.exports = {
  configAxiosApp: settings.produccion ? config.produccion : config.test,
  setting: settings,
};



