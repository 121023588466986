import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CContainer,
  CImg,
  CInputFile,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CRow,
} from "@coreui/react";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { configAxiosApp } from "src/config/config";
import swal from "sweetalert";
import coopsyslogo from "../../../img/CoopSysLogo.png";
import { makeid } from "src/Tools/makeid";
import { Icon } from "@iconify/react";

const Imagen = ({ data, deleted, token }) => {
  const [Deleting, setDeleting] = useState(false);
  const [active, setActive] = useState(false);

  const HandleDelete = (id) => {
    setDeleting(true);
    axios
      .delete(`${configAxiosApp.v02}/galery/movil/file`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${token}`, // Token de autorización
          "Content-Type": "application/json", // El tipo de contenido
        },
      })
      .then(() => {
        deleted();
      })
      .catch(() => {
        swal({
          title: "Movil Upload.",
          text: "Error en la Eliminacion del Archivo.",
        });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <>
      <CCol md={4}>
        <CCard className={`${Deleting ? "opaco cargando" : ""}`}>
          <CImg src={data.base64} onClick={() => setActive(true)} />
          <CCardFooter>
            <CRow>
              <CCol>{data.name} </CCol>
              <CCol md={4}>
                <CButton
                  onClick={() => {
                    swal({
                      title: "Estas Seguro ? ",
                      icon: "warning",
                      buttons: ["No", "Confirmar"],
                    }).then((respuesta) => {
                      if (respuesta) {
                        HandleDelete(data.fileid);
                        setActive(false);
                      }
                    });
                  }}
                >
                  <Icon icon="whh:delete" width={20} />
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
      <CModal
        show={active}
        onClose={() => setActive(!active)}
        closeOnBackdrop={false}
        centered
        size="lg"
      >
        <Fragment>
          <CModalBody>
            <CCol xs="12">
              <CImg src={data.base64} style={{ width: "80%", margin: 40 }} />
            </CCol>
          </CModalBody>
          <CModalFooter style={{ borderTop: 0 }}>
            <CCol>
              <CButton
                color="danger"
                className="btn"
                onClick={() => {
                  swal({
                    title: "Estas Seguro ? ",
                    icon: "warning",
                    buttons: ["No", "Confirmar"],
                  }).then((respuesta) => {
                    if (respuesta) {
                      HandleDelete(data.fileid);
                      setActive(false);
                    }
                  });
                }}
              >
                <Icon icon="whh:delete" width={25} />
                Eliminar Archivo
              </CButton>
            </CCol>
            <CCol style={{ textAlign: "end" }}>
              <CButton
                onClick={() => {
                  setActive(!active);
                }}
              >
                Cancel
              </CButton>
            </CCol>
          </CModalFooter>
        </Fragment>
      </CModal>
    </>
  );
};

export const UploadImagen = ({ match }) => {
  const token = match.params.id;
  const history = useHistory();
  const [Photos, setPhotos] = useState([]);
  const [Salvando, setSalvando] = useState(false);
  axios
    .get(`${configAxiosApp.v02}/galery/movil/tokenvalid`, {
      headers: {
        Authorization: `Bearer ${token}`, // Token de autorización
        "Content-Type": "application/json", // El tipo de contenido
      },
    })
    .then(() => {})
    .catch((e) => {
      console.log(e);
      swal({
        title: "Upload Movil",
        text: "Link ha caducado",
      });
      history.push("/");
    });

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    // let file = e.target.files;
    const files = Array.from(e.target.files);

    const processedItems = files.map((file) => {
      return new Promise((resolve) => {
        getBase64(file)
          .then((result) => {
            file["base64"] = result;

            const { base64, lastModified, name, size, type } = file;

            //Salvamos Imagen al Servidor
            const payload = {
              filetype: type,
              filebase64: base64,
              filemodify: lastModified,
              filename: name,
              filesize: size,
            };
            setSalvando(true);

            axios
              .post(`${configAxiosApp.v02}/galery/movil/save`, payload, {
                headers: {
                  Authorization: `Bearer ${token}`, // Token de autorización
                  "Content-Type": "application/json", // El tipo de contenido
                },
              })
              .then(({ data }) => {
                resolve({
                  type,
                  base64,
                  lastModified,
                  name,
                  size,
                  fileid: data.fileid,
                });
              })
              .catch((e) => {
                console.log(e);
                swal({
                  title: "Movil Upload.",
                  text: "Error al Subir Archivo.",
                });
              })
              .finally(() => {
                setSalvando(false);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });
    Promise.all(processedItems)
      .then((results) => {
        const sel = [];
        Photos.map((r) => sel.push(r));
        results.map((r) => sel.push(r));

        setPhotos(sel);
      }) // Devuelve todos los items procesados
      .catch((error) => console.error(error));
  };

  const idbutton = makeid(8);

  return (
    <div style={{width:'100%'}}>
      <CRow className="justify-content-center">
        <CCol md="12">
          <CCard>
            {/* Header con el logo y el título */}
            <CCardHeader className="d-flex align-items-center">
              <img src={coopsyslogo} alt="Logo" height="40" className="mr-3" />
              <h3>Subir Fotos</h3>
            </CCardHeader>

            {/* Cuerpo con el botón para subir archivos */}
            <CCardBody className="text-center">
              <CCol>
                <CRow style={{ display: "none" }}>
                  <CInputFile
                    custom
                    id={idbutton}
                    multiple
                    onChange={handleFileInputChange}
                    accept="image/*"
                  />
                  <CLabel htmlFor="custom-file-input" variant="custom-file">
                    Archivo
                  </CLabel>
                </CRow>
              </CCol>
              <CCol>
                <CCol>
                  <CRow>
                    {Photos.map((r, index) => {
                      return (
                        <Imagen
                          data={r}
                          key={index}
                          token={token}
                          deleted={() => {
                            const sel = [];
                            Photos.map((row) => {
                              if (row.fileid !== r.fileid) {
                                sel.push(row);
                              }
                            });

                            setPhotos(sel);
                          }}
                        />
                      );
                    })}
                  </CRow>
                </CCol>
              </CCol>

              <CButton
                color="primary"
                disabled={Salvando}
                onClick={(e) => {
                  document.getElementById(idbutton).click();
                  e.preventDefault();
                }}
                className={`${Salvando ? "opaco cargando" : ""}`}
              >
                <Icon icon="et:upload" width={25} />
                Subir Fotos
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};
