import clienteAxios from "./axios";

const domainAuth = (domain) => {
  if (domain) {
    clienteAxios.defaults.headers.common["x-auth-domain"] = domain;
  } else {
    delete clienteAxios.defaults.headers.common["x-auth-domain"];
  }
};

export default domainAuth;
