import {
  CButton,
  CCol,
   CImg,
   CModal,
  CModalBody,
  CModalFooter,
   CRow,
} from "@coreui/react";
 import React from "react";
 
import ilustracion from "../../../img/pricing-Illustration.svg";

export const CotizaEnCamino = ({ show, Close, DataForm }) => {
 
 

 

  return (
    <div>
      <CModal
        show={show}
        style={{ background: "#f6f6f6" }}
        size="lg"
        closeOnBackdrop={false}
        centered
      >
        <CModalBody style={{ minHeight: 315 }}>
 
            <CRow>
              <CCol xs="12">
                <div className="pricing-free-trial mt-5">
                  <div className="row">
                    <div className="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                      <div className="pricing-trial-content d-flex justify-content-between">
                        <div className="text-md-left mt-1 mr-3">
                          <CImg
                            src="https://www.coopsys.com.do/img/validacion.png"
                            style={{ width: 80,paddingBottom:10 }}
                          />
                          <h3 className="text-primary">Solicitud Recibido.</h3>
                          <p>
                            Hemos Recibido su Solicitud correctamente,
                            Estaremos preparando su Cotizacion, enviaremos a su correo {DataForm.correo}, esto puede
                            demorar unos minutos.
                            <br />
                          </p>
                        </div>
                        <CImg src={ilustracion} style={{width:250}} />
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
 
         </CModalBody>
        <CModalFooter>
          <CCol style={{ textAlign: "end" }}>
            <CButton
              color="primary"
              onClick={() => Close()}
              style={{ width: 130 }}
            >
              Aceptar
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default CotizaEnCamino;
