import moment from "moment";

const initialState = {
  cargando: true,
  contacto: [],
  error: {},
  seguimiento: {
    data: [],
    active: false,
  },
  estadisticas : {
    tasks: 0,
    coment: 0,
    process: 0,
    metas: 0,
    firstDay: '',
    lastDay:''
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "contacto_get":
      return {
        ...state,
        cargando: true,
      };
    case "contacto_get_success":
      return {
        ...state,
        cargando: false,
        contacto: action.payload,
      };
    case "contacto_error":
      return {
        ...state,
        cargando: false,
        // contacto: [],
        error: action.payload,
      };
    case "seguimiento_get_success":
      return {
        ...state,
        cargando: false,
        seguimiento: {
          data: action.payload,
          active: true,
        },
      };
    case "seguimiento->Close":
      return {
        ...state,
        cargando: false,
        seguimiento: {
          data: [],
          active: false,
        },
      };
    case "seguimiento_estadisticas_get_success":
      return {
        ...state,
        estadisticas: action.payload,
      };
    default:
      return state;
  }
}
