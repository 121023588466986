/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect } from "react";

export const Termminos = ({ display: disp }) => {
  useEffect(() => {
    setTimeout(() => {
      eterms();
    }, 3000);
  }, []);

  const eterms = () => {
    const pri = document.getElementById("terminosSupport")
    if(pri){
      const print = pri.contentWindow;
      print.document.open();
      print.document.write(terminos);
      print.document.close();
    }

  };

  //"height: 0px; width: 0px; position: absolute"
  return (
    <iframe
      id="terminosSupport"
      style={
        {
          height: "100%",
          width: "100%",
          minHeight: 600,
          border: 0,
          display : disp ? "block" : "none"
        }
      }
    ></iframe>
  );
};


export const terminos = `
<div class="maincontent">
 
 
<table
  width="100%"
  cellpadding="0"
  cellspacing="0"
  border="0"
  class="message"
>
  <tbody>
     <tr>
      <td colspan="2">
        <table width="100%" cellpadding="12" cellspacing="0" border="0">
          <tbody>
            <tr>
              <td>
                <div style="overflow: hidden">
                  <font size="-1"
                    ><div dir="ltr">
                      <br clear="all" />
                      <div>
                        <div dir="ltr" data-smartmail="gmail_signature">
                          <div dir="ltr">
                            <table
                              cellspacing="0"
                              align="center"
                              style="
                                font-family: calibri;
                                color: rgb(1, 33, 105);
                                text-align: justify;
                                margin-top: 5px;
                                margin-bottom: 25px;
                                width: 850px;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td width="100%" align="center">
                                    <p
                                      style="
                                        font-size: medium;
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        text-align: justify;
                                      "
                                    >
                                      <b
                                        ><font size="4"
                                          >Términos y Condiciones</font
                                        ></b
                                      ><b style="font-size: 16px">.</b>
                                    </p>
                                     
                                    <p
                                      style="
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        margin-bottom: 0em;
                                        font-size: 16px;
                                        text-align: justify;
                                      "
                                    >
                                      Los siguientes Términos y Condiciones
                                      (en adelante, "T&amp;C") rigen el uso
                                      del servicio CoopSys CoreBank de <b>DÁNGEL SOFTWARE ELECTRONIC SRL</b> (en adelante,
                                      "Dasoft" o "proveedor") por parte de los
                                      usuarios (en adelante, "usuario" o
                                      "usuarios").<br />
                                    </p>
                                    
                                    <p
                                      style="
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        margin-bottom: 0em;
                                        font-size: 16px;
                                        text-align: justify;
                                      "
                                    >
                                      <b>Detalles del Servicio:<br /></b>El
                                      servicio CoopSys CoreBank es una
                                      herramienta en línea proporcionada por
                                      Dasoft que permite a las cooperativas de
                                      crédito gestionar sus operaciones
                                      bancarias de manera eficiente y segura.
                                      Este servicio incluye funcionalidades
                                      como la gestión de cuentas,
                                      transferencias bancarias, consulta de
                                      saldos, entre otros.<br /><br /><b
                                        >El Usuario y el uso de la
                                        Herramienta:<br /></b
                                      >El uso de la herramienta CoopSys
                                      CoreBank se encuentra restringido
                                      únicamente a las cooperativas de crédito
                                      que hayan contratado los servicios de
                                      Dasoft. Los usuarios deben aceptar estos
                                      T&amp;C para poder utilizar el servicio.
                                      Además, los usuarios deben proporcionar
                                      información precisa y actualizada
                                      durante el proceso de registro.<br /><br /><b
                                        >Sobre las cuentas y el servicio
                                        prestado:<br /></b
                                      >Los usuarios son responsables de la
                                      seguridad y confidencialidad de las
                                      credenciales de acceso a sus cuentas. El
                                      usuario acepta que Dasoft no será
                                      responsable por cualquier pérdida o daño
                                      que resulte del acceso no autorizado a
                                      la cuenta del usuario. El usuario
                                      también acepta que Dasoft no será
                                      responsable por los errores en las
                                      transacciones realizadas por el usuario
                                      utilizando la herramienta CoopSys
                                      CoreBank.<br /><br /><b
                                        >Almacenamiento, Confidencialidad y
                                        Respaldo de la Información:<br /></b
                                      >Dasoft se compromete a mantener la
                                      confidencialidad de la información de
                                      los usuarios. El proveedor adoptará
                                      medidas razonables para proteger la
                                      información del usuario de cualquier
                                      acceso, divulgación o uso no autorizado.
                                      Dasoft realizará copias de seguridad
                                      periódicas de la información almacenada
                                      en la herramienta CoopSys CoreBank para
                                      garantizar la integridad y
                                      disponibilidad de la información.<br /><br /><b
                                        >Garantía de Disponibilidad del
                                        Servicio:<br /></b
                                      >Dasoft se compromete a proveer un
                                      servicio disponible el 99.9% del tiempo,
                                      salvo en caso de interrupciones
                                      programadas o no programadas para el
                                      mantenimiento y actualización del
                                      servicio. En caso de interrupciones no
                                      programadas que afecten al servicio,
                                      Dasoft se compromete a trabajar para
                                      restaurar el servicio en el menor tiempo
                                      posible.<br /><br /><b
                                        >Propiedad Intelectual y No Venta:<br /></b
                                      >Los usuarios reconocen que la
                                      herramienta CoopSys CoreBank y cualquier
                                      software, código, tecnología, diseño,
                                      documentación u otra propiedad
                                      intelectual relacionada son propiedad
                                      exclusiva de Dasoft. Los usuarios no
                                      adquieren ningún derecho de propiedad
                                      sobre la herramienta CoopSys CoreBank o
                                      cualquier propiedad intelectual
                                      relacionada. Los usuarios no pueden
                                      vender, transferir o sublicenciar el
                                      servicio o cualquier parte del mismo.<br /><br /><b
                                        >Proveedor de Servicio de Internet:<br /></b
                                      >El usuario reconoce que el acceso a la
                                      herramienta CoopSys CoreBank requiere
                                      una conexión a Internet y que el
                                      proveedor de servicio de Internet (ISP)
                                      del usuario puede cobrar cargos
                                      adicionales por el uso de datos. El
                                      usuario es responsable de cualquier
                                      costo adicional asociado con su uso de
                                      la herramienta CoopSys CoreBank.<br />
                                    </p>
                                    <p
                                      style="
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        margin-bottom: 0em;
                                        font-size: 16px;
                                        text-align: justify;
                                      "
                                    >
                                      <b>Forma de pago<br /></b>El Usuario
                                      deberá pagar las tarifas
                                      correspondientes a los servicios que
                                      desee contratar en el momento en que los
                                      solicite, según las tarifas vigentes en
                                      ese momento y que se encuentran
                                      disponibles en nuestro sitio web. El
                                      pago se realizará mediante los medios de
                                      pago disponibles en nuestro sitio
                                      web.<br /><br />En caso de que el
                                      Usuario opte por una forma de pago
                                      recurrente, se entenderá que ha
                                      autorizado de forma expresa y por
                                      escrito a Dasoft para que cobre
                                      periódicamente el monto correspondiente
                                      a la tarifa de los servicios
                                      contratados.<br /><br />El Usuario es el
                                      único responsable de proporcionar
                                      información precisa y actualizada sobre
                                      su forma de pago. En caso de que la
                                      información proporcionada sea
                                      incorrecta, incompleta o no pueda ser
                                      verificada, Dasoft se reserva el derecho
                                      de suspender o cancelar el servicio.<br /><br /><b
                                        >Sobre la Legalidad de la Información
                                        en la Cuenta<br /></b
                                      >El Usuario es el único responsable de
                                      la legalidad de la información que
                                      almacene o transmita a través de la
                                      Herramienta. Dasoft no se hace
                                      responsable de la veracidad, exactitud,
                                      legalidad, integridad o adecuación de
                                      dicha información.<br /><br />El Usuario
                                      garantiza que no utilizará la
                                      Herramienta para almacenar o transmitir
                                      información que viole cualquier ley o
                                      reglamentación aplicable, incluyendo
                                      pero no limitado a información que viole
                                      los derechos de propiedad intelectual de
                                      terceros, información que sea
                                      difamatoria o injuriosa, o información
                                      que contenga virus o cualquier otro
                                      código malicioso que pueda dañar o
                                      perjudicar a Dasoft o a terceros.<br /><br />Dasoft
                                      se reserva el derecho de eliminar
                                      cualquier información que, a su juicio,
                                      viole estas disposiciones.<br /><br /><b
                                        >Obligaciones de los usuarios<br /></b
                                      >El Usuario se compromete a utilizar la
                                      Herramienta de acuerdo con las
                                      condiciones establecidas en estos
                                      Términos y Condiciones, así como con las
                                      leyes, reglamentos y normas
                                      aplicables.<br /><br />El Usuario será
                                      el único responsable de mantener la
                                      confidencialidad de su contraseña y de
                                      cualquier otra información de acceso a
                                      la Herramienta. El Usuario se compromete
                                      a notificar a Dasoft de inmediato en
                                      caso de sospechar que su cuenta ha sido
                                      comprometida.<br /><br />El Usuario se
                                      compromete a no utilizar la Herramienta
                                      para realizar actividades que puedan
                                      dañar, sobrecargar, interferir o
                                      interrumpir el servicio, así como para
                                      realizar actividades que puedan violar
                                      los derechos de terceros o que sean
                                      ilegales o contrarias a la moral y las
                                      buenas costumbres.<br /><br /><b
                                        >Política de privacidad<br /></b
                                      >Dasoft se compromete a proteger la
                                      privacidad de la información personal de
                                      los Usuarios. La información recopilada
                                      será utilizada únicamente para fines
                                      relacionados con la prestación del
                                      servicio, así como para mejorar la
                                      calidad del servicio y proporcionar una
                                      mejor experiencia al Usuario.<br /><br />El
                                      Usuario acepta la Política de Privacidad
                                      disponible en nuestro sitio web.<br /><br /><b
                                        >Seguridad de la Cuenta<br /></b
                                      >Dasoft se compromete a tomar todas las
                                      medidas razonables para proteger la
                                      seguridad de la cuenta del Usuario,
                                      incluyendo la implementación de medidas
                                      de seguridad físicas, electrónicas y
                                      administrativas.<br /><br />El Usuario
                                      es responsable de proteger su cuenta y
                                      mantener la confidencialidad de su
                                      contraseña y cualquier otra información
                                      de acceso. El Usuario se compromete a
                                      notificar a Dasoft de inmediato en caso
                                      de sospechar que su cuenta ha sido
                                      comprometida.<br />
                                    </p>
                                    <p
                                      style="
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        margin-bottom: 0em;
                                        font-size: 16px;
                                        text-align: justify;
                                      "
                                    >
                                      <b
                                        >Interrupción y Terminación del
                                        Servicio.<br /></b
                                      >Dasoft se reserva el derecho de
                                      interrumpir o terminar el servicio de
                                      CoopSys CoreBank en cualquier momento y
                                      sin previo aviso si el usuario incumple
                                      los términos y condiciones establecidos
                                      en este documento o si se sospecha que
                                      el usuario ha realizado un uso indebido
                                      de la herramienta. En caso de que Dasoft
                                      decida interrumpir o terminar el
                                      servicio, el usuario acepta que Dasoft
                                      no será responsable de ninguna pérdida o
                                      daño que pueda sufrir el usuario como
                                      resultado de dicha interrupción o
                                      terminación.<br /><br /><b
                                        >Suspensión del Servicio.<br /></b
                                      >Dasoft se reserva el derecho de
                                      suspender el servicio de CoopSys
                                      CoreBank en cualquier momento y sin
                                      previo aviso si detecta alguna actividad
                                      sospechosa o inusual en la cuenta del
                                      usuario o si se sospecha que el usuario
                                      ha realizado un uso indebido de la
                                      herramienta. En caso de que Dasoft
                                      decida suspender el servicio, el usuario
                                      acepta que Dasoft no será responsable de
                                      ninguna pérdida o daño que pueda sufrir
                                      el usuario como resultado de dicha
                                      suspensión.
                                    </p>
                                    <p
                                      style="
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        margin-bottom: 0em;
                                        font-size: 16px;
                                        text-align: justify;
                                      "
                                    >
                                      <b
                                        >Política de Eliminación de Datos de
                                        Suscripciones Suspendidas<br /></b
                                      >Dasoft se compromete a proteger la
                                      información personal de sus usuarios y,
                                      por lo tanto, establece la siguiente
                                      política de eliminación de datos de
                                      suscripciones suspendidas.<br /><br />En
                                      caso de que una suscripción sea
                                      suspendida por un período de 10 días o
                                      más, Dasoft se reserva el derecho de
                                      eliminar todos los datos relacionados
                                      con esa suscripción. Esto incluye, pero
                                      no se limita a, la información de la
                                      cuenta, la información de pago y
                                      cualquier dato relacionado con la
                                      actividad de la cuenta.<br /><br />El
                                      usuario será notificado por correo
                                      electrónico antes de la eliminación de
                                      sus datos de suscripción suspendida. Si
                                      el usuario desea continuar utilizando el
                                      servicio después de la suspensión, se le
                                      solicitará que reactive su suscripción y
                                      proporcione una nueva información de
                                      pago.<br /><br />Dasoft no será
                                      responsable por la pérdida de datos o
                                      información resultante de la eliminación
                                      de los datos de suscripción suspendida.
                                      El usuario es responsable de mantener
                                      copias de seguridad de su información en
                                      su propio sistema o dispositivo.<br /><br />Esta
                                      política se aplica a todas las
                                      suscripciones suspendidas,
                                      independientemente de la razón de la
                                      suspensión. Dasoft se reserva el derecho
                                      de modificar esta política en cualquier
                                      momento sin previo aviso.<br /><br /><b
                                        >Política de Cancelación y
                                        Reembolso.<br /></b
                                      >El usuario puede cancelar el servicio
                                      de CoopSys CoreBank en cualquier
                                      momento, pero no se realizarán
                                      reembolsos por el tiempo restante del
                                      servicio contratado. Dasoft se reserva
                                      el derecho de cancelar el servicio en
                                      caso de que el usuario incumpla los
                                      términos y condiciones establecidos en
                                      este documento o si se sospecha que el
                                      usuario ha realizado un uso indebido de
                                      la herramienta. En caso de que Dasoft
                                      decida cancelar el servicio, el usuario
                                      acepta que Dasoft no será responsable de
                                      ninguna pérdida o daño que pueda sufrir
                                      el usuario como resultado de dicha
                                      cancelación.<br />
                                    </p>
                                    <p
                                      style="
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        margin-bottom: 0em;
                                        font-size: 16px;
                                        text-align: justify;
                                      "
                                    >
                                      <b
                                        >Legislación Aplicable y
                                        Jurisdicción<br /></b
                                      >Este acuerdo será regido e interpretado
                                      de acuerdo con las leyes de la República
                                      Dominicana. Cualquier disputa que surja
                                      en relación con este acuerdo será
                                      sometida a la jurisdicción exclusiva de
                                      los tribunales de la República
                                      Dominicana. Si alguna disposición de
                                      estos Términos y Condiciones se
                                      considera inválida o inaplicable por un
                                      tribunal competente, dicha disposición
                                      se limitará o eliminará en la medida
                                      mínima necesaria para que los Términos y
                                      Condiciones permanezcan en pleno vigor y
                                      efecto.<br /><br />Al aceptar estos
                                      Términos y Condiciones, los usuarios
                                      reconocen y aceptan que han leído y
                                      comprendido todo el contenido del mismo,
                                      así como que están obligados por sus
                                      términos y condiciones. Además, aceptan
                                      que cualquier violación de estos
                                      Términos y Condiciones puede resultar en
                                      la terminación inmediata de su acceso a
                                      los servicios de CoopSys CoreBank de
                                      Dasoft SRL sin previo aviso.<br />
                                    </p>
                                    <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        &nbsp;&nbsp;<br />
                                      </p>
                                    <p
                                      style="
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        margin-bottom: 0em;
                                        font-size: 16px;
                                        text-align: justify;
                                      "
                                    ></p>
                                  </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <b >D ANGEL SOFTWARE ELECTRONIC SRL, (DASOFT SRL)<br /></b
                                        >Matanza Adentro, Entrada de Viva No.
                                        63<br />Santiago de los Caballeros,
                                        Rep.Dom.<br />www.dasoft.com.do<br />Tel.:
                                        <span style="display: inline"
                                          > (849) 937-0818</a
                                          > </span>
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 2em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <b style="font-size: 14px"><br /></b
                                        ><b style="font-size: 14px"
                                          >R.N.C.: 1-31-05231-2</b
                                        >
                                      </p>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                            <table
                              cellspacing="0"
                              align="center"
                              style="
                                font-family: calibri;
                                color: rgb(1, 33, 105);
                                text-align: justify;
                                margin-top: 25px;
                                margin-bottom: 25px;
                                width: 600px;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td align="center">
                                    <p
                                      style="
                                        margin-bottom: 1em;
                                        margin-left: 1.5em;
                                        margin-right: 1.5em;
                                        font-size: 14px;
                                        text-align: justify;
                                      "
                                    >
                                      <br />
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </font>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
</div>

`;
