import {
  GETDEBITOCREDITO,
  GETDEBITOCREDITO_OK,
  GETDEBITOCREDITO_ERROR,
  POSTDEBITOCREDITO,
  POSTDEBITOCREDITO_OK,
  POSTDEBITOCREDITO_ERROR,
  CONSDEBITOCREDITO,
  CONSDEBITOCREDITO_OK,
  CONSDEBITOCREDITO_ERROR,
} from "../types/index";

const initialState = {
  infoCuenta: {
    cuenta: "",
    nombre: "",
    listtipomovi: [],
    balance:{balance:0,disponible:0}
  },
  consulta: false,
  documento:{},
  transaccion:false,
  consDocumento:{},
  consultando:false
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GETDEBITOCREDITO:
      return {
        ...state,
        infoCuenta: action.payload,
        consulta: true,
      };
    case GETDEBITOCREDITO_OK:
      return {
        ...state,
        infoCuenta: action.payload,
        consulta: false,
      };
    case GETDEBITOCREDITO_ERROR:
      return {
        ...state,
        error: action.payload,
        consulta: false,
      };

      case CONSDEBITOCREDITO:
        return {
          ...state,
          consDocumento: action.payload,
          consultando: true,
        };
      case CONSDEBITOCREDITO_OK:
        return {
          ...state,
          consDocumento: action.payload,
          consultando: false,
        };
      case CONSDEBITOCREDITO_ERROR:
        return {
          ...state,
          error: action.payload,
          consultando: false,
        };

    case POSTDEBITOCREDITO:
      return {
        ...state,
        documento: action.payload,
        transaccion: true,
      };
    case POSTDEBITOCREDITO_OK:
      return {
        ...state,
        documento: action.payload,
        transaccion: false,
      };
    case POSTDEBITOCREDITO_ERROR:
      return {
        ...state,
        error: action.payload,
        transaccion: false,
      };
    default:
      return state;
  }
}
