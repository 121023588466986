import { combineReducers } from "redux";
import changeReducer from "./changeReducer";
import usuarioReducer from "./usuarioReducer";
import sociosReducer from "./sociosReducer";
import clientes from "./clientesReducer";
import componentSocioReducer from "./componentSocioReducer";
import planAhorroReducer from "./planAhorroReducer";
import sociosEstadoReducer from "./sociosEstadoReducer";
import SolicitudSuscripcionReducer from "./SolicitudSuscripcionReducer";
import debitoCreditoReducer from "./debitoCreditoReducer";
import SupportReducer from "./SupportReducer";
import securityReducer from "./securityReducer";
import settingReducer from "./settingReducer";
import appReducer from "./appReducer";
import cnt from "../../views/cnt/store/reducer/cnt";
import Documentacion from "./Documentacion";
import productosReducer from "./productosReducer"
import geoLocalizacionReducer from "./geoLocalizacionReducer";
import sucursalReducer from "./sucursalReducer";
import transaccionesReducer from "./transaccionesReducer.js";
import BoxCenter from "./BoxCenter";
import crmReducer from "./crmReducer";
import ShopCardReducer from "src/shop_cart/store/ShopCardReducer";
import conciliacionReducer from "./conciliacionReducer";
import segurosReducer from "./segurosReducer";
import whatsappReducer from "./whatsappReducer";
import storeManager from "src/adminApp/Store/storeManager";
import invoiceReducer from "./invoiceReducer";
import settingOTP from "./settingOTP";
 
export default combineReducers({
  usuario: usuarioReducer,
  socios: sociosReducer,
  changes: changeReducer,
  clientes: clientes,
  socios_component: componentSocioReducer,
  planAhorro: planAhorroReducer,
  estadoCuenta: sociosEstadoReducer,
  solicitudSuscripcion : SolicitudSuscripcionReducer,
  debitoCredito : debitoCreditoReducer,
  support: SupportReducer,
  security: securityReducer,
  setting: settingReducer,
  settingotp :  settingOTP,
  SettingProductos : productosReducer,
  SettingSucursal : sucursalReducer,
  SettingTransacciones : transaccionesReducer,
  settingWhatsapp: whatsappReducer,
  Dapp: appReducer,
  cnt: cnt,
  manager: storeManager,
  Documentaciones : Documentacion,
  geoLocalizacion: geoLocalizacionReducer,
  boxCenter : BoxCenter,
  crm: crmReducer,
  ShopCard: ShopCardReducer,
  conciliacion : conciliacionReducer,
  seguro : segurosReducer,
  invoice : invoiceReducer,

});
