import { CModal, CModalBody } from "@coreui/react";
import React from "react";

const Spinner = ({value,...res}) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status"></div>
      <strong className="ml-1" {...res}>{value===undefined ?  "Descargando...." : value }</strong>
    </div>
  );
};

export const Spinnerblue = ({value,style}) => {
  return (
    <div className="d-flex justify-content-center" style={{ color: "blue",...style }}>
      <div className="spinner-border" role="status"></div>
      <strong className="ml-1">{value===undefined ? "Cargando...." : value} </strong>
    </div>
  );
};

export const DisabledAutorization = ({value,text}) => {
  return (
    <CModal
      show={true}
      size="sm"
      centered
      closeOnBackdrop={false}
    >
      <CModalBody style={{ color: "blue",textAlign:'center' }}
 >
          { text ? 
          <strong className="ml-3 pt-1">
           {text}
          </strong>
          :
          <strong className="ml-3 pt-1">
          ¿No ve el navegador seguro ? 
          Abriremos la ventana nuevamente para que pueda completar el proceso de vinculacion
          </strong>
          }
          
       </CModalBody>
    </CModal>
  );
};

export const Downloading = ({value,text}) => {
  return (
    <CModal
      show={true}
      color="success"
      size="sm"
      centered
      closeOnBackdrop={false}
    >
      <CModalBody>
        <div
          className="d-flex justify-content-center"
          style={{ color: "blue" }}
        >
          <div className="spinner-border" role="status"></div>
          <strong className="ml-3 pt-1">
            {value===undefined ?  "Descargando Archivo...." : value }</strong>
        </div>
      </CModalBody>
    </CModal>
  );
};


export const ModalCargando = ({value,text}) => {
  return (
    <CModal
      show={true}
      color="success"
      size="sm"
      centered
      closeOnBackdrop={false}
    >
      <CModalBody>
        <div
          className="d-flex justify-content-center"
          style={{ color: "blue" }}
        >
          <svg
        data-v-60882612=""
        xmlns="http://www.w3.org/2000/svg"
        width="76px"
        height="76px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={
          {
            margin : 'audo',
            background : 'rgb(255, 255, 255)',
            display: 'block'
          }
        }
        // style="margin: auto; background: rgb(255, 255, 255); display: block;"
      >
        <circle
          data-v-60882612=""
          cx="50"
          cy="50"
          fill="none"
          stroke="#00b19d"
          stroke-width="10"
          r="38"
          stroke-dasharray="179.0707812546182 61.690260418206066"
        >
          <animateTransform
            data-v-60882612=""
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="0.5s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
          <strong className="ml-3 pt-1">
            {value===undefined ?  "Descargando Archivo...." : value }</strong>
        </div>
      </CModalBody>
    </CModal>
  );
}; 





export default Spinner;
