const initialState = {
  consultando: true,
  productos: [],
  tipo: [],
  current_producto: {},
  editando: false,
  refresh: false,
  EditTerminos: false,
  EditTerminosHtml: false,
  EditTerminosView : false
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "PRODUCTOS-PATCH":
      return {
        ...state,
        consultando: action.payload,
        refresh: false,
      };
    case "PRODUCTOS-PATCH->ok":
      return {
        ...state,
        consultando: false,
        productos: action.payload.productos,
        tipo: action.payload.tipo,
      };
    case "PRODUCTOS-GET->ok":
      return {
        ...state,
        editando: true,
        current_producto: action.payload,
      };
    case "PRODUCTOS-NEW":
      return {
        ...state,
        editando: true,
        current_producto: {
          producto: 0,
          tipo: action.payload.tipo,
          descripcion: "",
          nomenclatura: "",
          interno: "N",
          retenido: "0.00",
          tasint: "0.00",
          terminos: " ",
          divisa: "DOP",
          cuenta_cnt: "",
          cnt_cuenta: "",
          creando:1
        },
      };
    case "PRODUCTOS-GET-TERMINOS->ok" :
      return {
        ...state,
        EditTerminos: true,
        current_producto: action.payload,
      };
    case "PRODUCTOS-EDIT-TERMINOS->view" :
      return {
        ...state,
        EditTerminosView: true,
        EditTerminos : false,
      };
    case "PRODUCTOS-EDIT-TERMINOS->html" :
      return {
        ...state,
        EditTerminosHtml: true,
        EditTerminos : false,
      };
    case "PRODUCTOS-EDIT-TERMINOS->Close":
      return {
        ...state,
        EditTerminosView: false,
        EditTerminosHtml: false,
        EditTerminos : false,
      };
    case "PRODUCTOS-POST->ok":
      return {
        ...state,
        editando: false,
        current_producto: {},
        refresh: true,
      };
    case "PROD-CHANGETERMINOS->ok" :
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        EditTerminosView: false,
        EditTerminosHtml: false,
        current_producto: {},
      };
    case "PRODUCTOS-EDIT->Close" :
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    case "PRODUCTOS-GET-TERMINOS->Close":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    default:
      return state;
  }
}
