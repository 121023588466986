import React from 'react'
import {
   CCol,
  CContainer,
 
  CRow
} from '@coreui/react'
 import { Notificaciones } from 'src/views/billing/Products/Notificaciones/Notificaciones'

export const VersionTerminos = '2023.10v03R';

const UpdateTerminos = () => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center" style={{minHeight:'60vh'}} >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            
            <Notificaciones />
            
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default UpdateTerminos
