const initialState = {
  whatsapp: {
    status: "inicializando",
    substatus: "",
    process: false,
    show: false,
    qr: "",
  },
  whatsappMessage: {
    message: [],
    show: false,
    cargando : false
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "WSQR":
      return {
        ...state,
        whatsapp: {
          ...state.whatsapp,
          process: true,
          show: false,
          qr: "",
        },
      };
    case "WSQR->Success":
      return {
        ...state,
        whatsapp: {
          ...state.whatsapp,
          process: false,
          show: true,
          qr: action.payload,
        },
      };
    case "WSQR-CLOSE":
      return {
        ...state,
        whatsapp: {
          ...state.whatsapp,
          process: false,
          show: false,
          
          qr: "",
        },
      };
    case "WSQR-MESSAGE" :
      return {
        ...state,
        whatsappMessage: {
          message: [],
          show: true,
          cargando : true
        },
      };
    case "WSQR-MESSAGE-CLOSE" : 
    return {
      ...state,
      whatsappMessage: {
        message: [],
        show: false,
        cargando : false
      },
    };
    case "WSQR-MESSAGE->Success" :
        return {
          ...state,
          whatsappMessage: {
            message: action.payload,
            show: true,
            cargando : false
          },
        };
    case "WS-STATUS->Success":
      return {
        ...state,
        whatsapp: {
          ...state.whatsapp,
          ...action.payload,
          show: action.payload.status === "qr" ? state.whatsapp.show : false,
        },
      };
    default:
      return state;
  }
}
