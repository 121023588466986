import {
  CButton,
  CCard,
  CCol,
  CImg,
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import ilustracion from "../../img/pricing-Illustration.svg";
import ilustracion from "../../img/illustration-4-cropped.png";
import Axios2 from "src/config/axios2v";

export const OTPSetting = () => {
  const dispatch = useDispatch();
  const [activeModal, setActive] = useState(false);
  const history = useHistory();
  const { show } = useSelector((state) => state.settingotp);

  return (
    <div>
      <CModal
        show={show}
        onClose={() => dispatch({ type: "SETTING->OTP-CLOSE" })}
        closeOnBackdrop={false}
        centered
        size="xl"
      >
        <Fragment>
          <CModalBody>
            <CCol xs="12">
              <div className="pricing-free-trial mt-5">
                <div className="row">
                  <div className="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                    <div className="pricing-trial-content d-flex justify-content-between">
                      <div className="text-md-left mt-3">
                        <h3 className="text-primary">
                          Actualiza un Metodo de Verificacion de seguridad
                        </h3>
                        <p>
                          Para garantizar la protección de los datos sensibles,
                          es crucial actualizar nuestro método de verificación
                          de seguridad. La implementación de un método robusto
                          de autenticación es fundamental para asegurar la
                          autenticidad y la integridad de los datos. Este paso
                          es esencial para mantener la confidencialidad de la
                          información y proteger tanto a nuestros usuarios como
                          a nuestra organización frente a posibles
                          vulnerabilidades.
                          <br />
                        </p>
                        <CButton
                          className="btn btn-primary mt-2 mt-lg-3"
                          onClick={() => {
                            dispatch({ type: "SETTING->OTP-CLOSE" });
                            history.push("/setting/profiles/otp");
                          }}
                        >
                          Actualizar Informacion
                        </CButton>
                      </div>
                      <CImg src={ilustracion} width={200}/>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CModalBody>
          <CModalFooter style={{ borderTop: 0 }}>
            <CButton
              onClick={() => {
                dispatch({ type: "SETTING->OTP-CLOSE" });
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </Fragment>
      </CModal>
    </div>
  );
};

export const DashOTPSetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [process, setProcess] = useState(false);
  const [activeotp, setActiveOTP] = useState(false);

  const [Data, setData] = useState({
    otp: [],
    optactive: false,
  });
  const consulta = () => {
    setProcess(true);
    Axios2.get("/setting/user/otp")
      .then(({ data }) => {
        setData(data);
        setProcess(false);
        if (data.otp.length === 0) {
          setActiveOTP(true);
        }
      })
      .catch(() => setProcess(false));
  };
  useEffect(() => consulta(), []);

  if (activeotp === false) {
    return <></>;
  }
  return (
    <CCol md="6">
      <CCard>
        <CCol xs="12">
          <div>
            <div
              className="pricing-free-trial pl-5 mt-5"
              style={{ height: 225 }}
            >
              <div className="row">
                <div className="pl-2">
                  <div className="pricing-trial-content d-flex justify-content-between">
                    <div className="text-md-left mt-3">
                      <h3 className="text-primary">Metodo OTP Requerido !</h3>
                      <p>
                        Actualizar nuestra verificación de seguridad es crucial
                        para proteger datos sensibles. Un método robusto de
                        autenticación asegura la autenticidad e integridad de
                        los datos, manteniendo la confidencialidad e impidiendo
                        vulnerabilidades.
                      </p>
                      <CButton
                        className="btn btn-primary mt-2 mt-lg-3"
                        onClick={() => {
                          dispatch({ type: "SETTING->OTP-CLOSE" });
                          history.push("/setting/profiles/otp");
                        }}
                      >
                        Actualizar Informacion
                      </CButton>
                    </div>
                    <CImg src={ilustracion} width={150} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CCard>
    </CCol>
  );
};
