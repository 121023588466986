import {
  SUPPORTLOADING_ERROR,
  SUPPORTLOADING,
  SUPPORTLOADING_TOKEN,
  SUPPORTINBOX,
  SUPPORTINBOX_OK,
  SUPPORTINBOX_ERROR,
  SUPPORTINVOICE,
  SUPPORTINVOICE_OK,
  SUPPORTINVOICE_ERROR
} from "../types/index";

const initialState = {
  conectado: false,
  conectando:false,
  tokens: {},
  data: {},
  inbox:[],
  inboxitemsCount:0,
  invoice:[],
  invoiceBalance:0,
  invoiceNombre:'',
  invoiceCount: 0,
  invoiceStatus:1,
  invoiceLoading: true,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SUPPORTLOADING:
      return {
        ...state,
        conectando: action.payload,
      };

    case SUPPORTLOADING_TOKEN:
      return {
        ...state,
        conectado: true,
        conectando:false,
        tokens: action.payload,
      };
    case SUPPORTLOADING_ERROR:
      return {
        ...state,
        error: action.payload,
        conectado: false,
        conectando:false,
      };


      case SUPPORTINBOX:
        return {
          ...state,
          leyendo: action.payload,
        };
  
      case SUPPORTINBOX_OK:
        return {
          ...state,
          leyendo: true,
          inbox: action.payload.inbox,
          inboxitemsCount: action.payload.inboxitemsCount
        };
      case SUPPORTINBOX_ERROR:
        return {
          ...state,
          error: action.payload,
          leyendo: false,
        };
      case SUPPORTINVOICE :
        return {
          ...state,
          invoiceLoading: true,
        };
        case SUPPORTINVOICE_OK :
          return {
            ...state,
            invoiceLoading: false,
            invoice:action.payload.Invoice,
            invoiceCount:action.payload.InvoiceitemsCount,
            invoiceBalance: action.payload.balance,
            invoiceNombre: action.payload.nombre,
            invoiceStatus: action.payload.estado
          };
          case SUPPORTINVOICE_ERROR :
            return {
              ...state,
              invoiceLoading: false,
              invoice:[],
              invoiceError: action.payload
            };
    default:
      return state;
  }
}
