import {
    APP, 
  } from "../types/index";
  
    
  const initialState = {
    current_app: 'corebank',
    modalSupport : false,
    SupportReference: ''
  };
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
      case APP:
        return {
          ...state,
          current_app: action.payload,
        };
      case 'SUPPORTTICKET':
          return {
            ...state,
            modalSupport: action.payload,
            SupportReference: action.referencia
          };
      default:
        return state;
    }
  }
  