import {
  SOLICITUD_SUSCRIPCIONES_GET,
  SOLICITUD_SUSCRIPCIONES_GET_OK,
  SOLICITUD_SUSCRIPCIONES_GET_ERROR,
  SOLICITUD_SUSCRIPCION_GET,
  SOLICITUD_SUSCRIPCION_GET_OK,
  SOLICITUD_SUSCRIPCION_GET_ERROR,
  SOLICITUD_SUSCRIPCION_POST,
  SOLICITUD_SUSCRIPCION_POST_OK,
  SOLICITUD_SUSCRIPCION_POST_ERROR,
} from "../types/index";

const initialState = {
  cargando: false,
  update: false,
  solicitudSuscripciones: [],
  solicitudSuscripcion: [],
  actualizado: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SOLICITUD_SUSCRIPCIONES_GET:
      return {
        ...state,
        action: action.payload,
        actualizado: false,
        cargando: true,
      };
    case SOLICITUD_SUSCRIPCIONES_GET_OK:
      return {
        ...state,
        solicitudSuscripciones: action.payload,
        cargando: false,
      };
    case SOLICITUD_SUSCRIPCIONES_GET_ERROR:
      return {
        ...state,
        error: action.payload,
        cargando: false,
      };

    case SOLICITUD_SUSCRIPCION_GET:
      return {
        ...state,
        action: action.payload,
        actualizado: false,
        cargando: true,
      };
    case SOLICITUD_SUSCRIPCION_GET_OK:
      return {
        ...state,
        solicitudSuscripcion: action.payload,
        cargando: false,
      };
    case SOLICITUD_SUSCRIPCION_GET_ERROR:
      return {
        ...state,
        error: action.payload,
        cargando: false,
      };
    case SOLICITUD_SUSCRIPCION_POST:
      return {
        ...state,
        action: action.payload,
        actualizado: false,
        update: true,
      };
    case SOLICITUD_SUSCRIPCION_POST_OK:
      return {
        ...state,
        action: action.payload,
        actualizado: true,
        update: false,
      };
    case SOLICITUD_SUSCRIPCION_POST_ERROR:
      return {
        ...state,
        error: action.payload,
        update: false,
      };

    default:
      return state;
  }
}
