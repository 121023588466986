import {
  CButton,
  CCol,
  CImg,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import React, { Fragment, useState } from "react";
import Axios2 from "src/config/axios2v";
import domainAuth from "src/config/domain";
import swal from "sweetalert";
import ilustracion from "../../../img/illustration-9.png";

export const RecuperarContrasena = ({ active, setActive }) => {
  const [dato, setDato] = useState({
    username: "",
  });

  const { username } = dato;

  const handleChange = (e) => {
    setDato({
      ...dato,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "username") {
      const sp = e.target.value;
      const ss = sp.split("@");

      if (typeof ss[1] !== "undefined") {
        domainAuth(ss[1]);
        window.localStorage.setItem("domain", ss[1]);
      } else {
        window.localStorage.removeItem("domain");
      }
    }
  };
  const handleRescue = async (e) => {
    // Validacion
    if (username.trim() === "") {
      swal({
        title: "Error de validacion.",
        text: "Favor de introducir Nombre de usuario.",
        icon: "warning",
        button: "Aceptar",
      });
      return;
    }

    const data = {
      username,
    };
    try {
      await Axios2.get("/auth/rescues", {
        params: data,
      })
        .then(({ data }) => {
          setActive(false)
          swal({
            title: "Confirmacion Enviada.",
            text: data,
            icon: "success",
            button: "Aceptar",
          });
        })
        .catch((error) => {
          swal({
            title: "Error de validacion.",
            text: error.response.data,
            icon: "warning",
            button: "Aceptar",
          });
        });
    } catch (error) {
      swal({
        title: `${error.response.status}`,
        text: error.response.statusText,
        icon: "warning",
        button: "Aceptar",
      });
    }
  };

  return (
    <Fragment>
      <CModal
        show={active}
        onClose={() => setActive(!active)}
        closeOnBackdrop={false}
        centered
        size="lg"
      >
        <Fragment>
          <CModalBody>
            <CCol xs="12">
              <div class="pricing-free-trial mt-5">
                <div class="row">
                  <div class="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                    <div class="pricing-trial-content d-flex justify-content-between">
                      <div class="text-md-left mt-5">
                        <h3 class="text-primary">Recuperacion de contraseña</h3>
                        <div class="form-group">
                          <label class="text-muted">Usuario a Recuperar</label>{" "}
                          <CInput
                            className="form-control"
                            type="text"
                            name="username"
                            value={username}
                            placeholder="Ingrese correo electronico"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <CImg src={ilustracion} style={{ width: 250 }} />
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CModalBody>
          <CModalFooter style={{ borderTop: 0 }}>
            <CButton
              type="submit"
              color="primary"
              style={{
                width: "30%",
                marginRight: 15,
                left: 30,
                position: "absolute",
              }}
              onClick={handleRescue}
            >
              Recuperar
            </CButton>
            <CButton
              style={{ width: "30%", marginLeft: 15 }}
              onClick={() => {
                setActive(!active);
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </Fragment>
      </CModal>
    </Fragment>
  );
};
