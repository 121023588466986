import clienteAxios from "./axios";
import Axios2Report from "./axios2Report";
import Axios2 from "./axios2v";
import AxiosManager from "./axiosManager";


export const AuthorizationAuth = (Authorization) => {
  if (Authorization) {
    clienteAxios.defaults.headers.common["Authorization"] = `Bearer ${Authorization}`;
  } else {
    delete clienteAxios.defaults.headers.common["Authorization"];
  }

  if (Authorization) {
    Axios2.defaults.headers.common["Authorization"] = `Bearer ${Authorization}`;
    Axios2Report.defaults.headers.common["Authorization"] = `Bearer ${Authorization}`;
  } else {
    delete Axios2.defaults.headers.common["Authorization"];
    delete Axios2Report.defaults.headers.common["Authorization"];
  }

};

export const AuthorizationManagerAuth = (Authorization) => {
  if (Authorization) {
    AxiosManager.defaults.headers.common["Authorization"] = `Bearer ${Authorization}`;
  } else {
    delete AxiosManager.defaults.headers.common["Authorization"];
  }
};



