const initialState = {
  show: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SETTING->OTP":
      return {
        ...state,
        show: true,
      };
      case "SETTING->OTP-CLOSE":
        return {
          ...state,
          show: false,
        };
    default:
      return state;
  }
}
