const initialState = {
  type: {
    cargando: false,
    type: [],
  },
  Plans: {
    cargando: false,
    Plans: [],
  },
  solicitud: {
    show: false,
    data: {},
  },
  consulta: {
    show: false,
    data: [],
  },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SEGUROS_VEHICULOS":
      return {
        ...state,
        type: {
          ...state.type,
          cargando: true,
        },
      };

    case "SEGUROS_VEHICULOS_OK":
      return {
        ...state,
        type: {
          ...state.type,
          cargando: false,
          type: action.payload,
        },
      };

    //Seguros
    case "SEGUROS_PLANS":
      return {
        ...state,
        Plans: {
          ...state.Plans,
          cargando: true,
        },
      };

    case "SEGUROS_PLANS_OK":
      return {
        ...state,
        Plans: {
          ...state.Plans,
          cargando: false,
          Plans: action.payload,
        },
      };
    case "SEGUROS_SOLICITUD_OK":
      return {
        ...state,
        solicitud: {
          show: true,
          data: action.payload,
        },
      };
    case "SEGUROS_SOLICITUD_CLOSE":
      return {
        ...state,
        solicitud: {
          show: false,
          data: {},
        },
      };
    case "SEGUROS_CONSSOLICITUD_OK":
      return {
        ...state,
        consulta: {
          show: true,
          data: action.payload,
        },
      };
    case "SEGUROS_CONSSOLICITUD_CLOSE":
      return {
        ...state,
        consulta: {
          show: false,
          data: [],
        },
      };
    default:
      return state;
  }
}
