/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, Fragment } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CImg,
  CInput,
  CLabel,
} from "@coreui/react";
// Redux
import { useDispatch } from "react-redux";

// Otros componentes
import "../login/login.css";
import coopsyslogo from "../../../img/CoopSysLogo.png";
import { Terminos } from "../login/Terminos";
import Axios2 from "src/config/axios2v";
import { Downloading } from "src/reusable/Spinner";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CotizaEnCamino } from "./CotizaEnCamino";

const Cotiza = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [enviando, setenviando] = useState(false);
  const [registrando, setRegistrando] = useState(false);
  const [registrado, setRegistrado] = useState(false);
  // State local
  const [DataForm, setDataForm] = useState({
    cooperativa: "",
    telefono: "",
    direccion: "",
    representante: "",
    correo: "",
    password: "",
    cliente: "",
    usuario: "",
  });

 
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validacion
    if ( DataForm.cliente.trim() === ""
    || DataForm.representante.trim() === ""
    
    ) {
       swal(
        {
          icon: 'warning',
          title : 'Campos Requeridos !',
          text: `Tos los campos son necesarios`,
          buttons : 'Aceptar'
        }
      );



      return;
    }

    // Enviar al action
 
    setenviando(true);
    Axios2.post("/cotiza", DataForm ).then(({ data }) => {
      setRegistrado(true)
      setenviando(false);
    }).catch((error)=>{
      setRegistrando(false);
      setenviando(false);
      swal(
        `Error ${error.response.status.toString()}`,
        `${error.response.data}  `,
        "warning"
      );
    });
  };

   
  useEffect(() => {
    const loginElement = document.getElementById("logins");

    const script = document.createElement("script");

    script.src = "https://static.coopsys.app/js/facebook.js";
    script.async = true;

    loginElement.appendChild(script);
  }, []);

   
  const handleChange = (e) => {
      setDataForm({
        ...DataForm,
        [e.target.name]: e.target.value,
      });
   };

  console.log(DataForm)
  return (
    <Fragment>
      {registrando && <Downloading value="Registrando" />}
      <section className="main-section" id="logins">
        <div className="login-section">
          <div className="login-wrapper">
            <CImg id="logo" src={coopsyslogo} />
            <h2 className="text-left">Formulario de Cotizacion.</h2>
            <p className="mb-4 card-text">
              Realice su Solicitud de Cotizacion formalizado
            </p>
            <CForm onSubmit={handleSubmit}>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel htmlFor="text-input" className="LoginLabel">
                    Cooperativa
                  </CLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CInput
                    className="form-control LoginInput2"
                    name="cliente"
                    value={DataForm.cliente}
                    placeholder="Nombre Corto / Comercial / @Dominio"
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel htmlFor="text-input" className="LoginLabel">
                    Telefono
                  </CLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CInput
                    className="form-control LoginInput2"
                    name="telefono"
                    value={DataForm.telefono}
                    placeholder="Telefono"
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel htmlFor="text-input" className="LoginLabel">
                    Direccion
                  </CLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CInput
                    className="form-control LoginInput2"
                    name="direccion"
                    value={DataForm.direccion}
                    placeholder="Direccion"
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel htmlFor="text-input" className="LoginLabel">
                    Representante
                  </CLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CInput
                    className="form-control LoginInput2"
                    name="representante"
                    value={DataForm.representante}
                    placeholder="Nombre del representante"
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel htmlFor="text-input" className="LoginLabel">
                    Correo
                  </CLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <CInput
                    className="form-control LoginInput2"
                    name="correo"
                    value={DataForm.correo}
                    placeholder="Correo"
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>

              <div className="row justify-content-center my-3 px-3">
                {" "}
                <CButton
                  type="submit"
                  color="primary"
                  className="btn-block LoginInput2"
                  disabled={enviando}
                >
                  Solcitar Cotizacion !
                </CButton>
              </div>
            </CForm>
            <div className="row justify-content-center my-2 mt-4">
              <CButton
                onClick={() =>
                  dispatch({ type: "POLICITA_PRIVACIDAD", payload: true })
                }
              >
                <small className="text-muted">Politica de Privacidad</small>
              </CButton>
            </div>
            <p className="text-center mt-2">
              <span className="me-25">¿Ya Estas registrado?</span>
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#/auth/login">
                  <span>Inicia Seccion</span>
                </a>
              }
            </p>
          </div>
        </div>
        <div id="fb-root"></div>
        <div id="fb-customer-chat" className="fb-customerchat"></div>
      </section>

       
      <CotizaEnCamino
        show={registrado}
        DataForm={DataForm}
        Close={() => {
          setRegistrado(false);
          setTimeout(() => history.push("/login"), 400);
        }}
      />
      <Terminos />
    </Fragment>
  );
};

export default Cotiza;
