import {
  CButton,
  CCol,
  CImg,
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ilustracion from "../../../../img/pricing-Illustration.svg";

export const TeEsperamos = () => {
  const [activeModal, setActive] = useState(false);
  useEffect(() => setActive(true), []);
  const history = useHistory();
  const { status } = useSelector((state) => state.setting);

  if (status.load === 0) { return <></> }
  const { unpaid, active, alerts } = status;

  //unpaid===0 && 
  if (active === 1 && alerts === 0) { return <></> }
  return (
    <div>
      <CModal
        show={activeModal}
        onClose={() => setActive(!activeModal)}
        closeOnBackdrop={false}
        centered
        size="xl"
        color="danger"
      >
        <Fragment>
          <CModalBody>
            <CCol xs="12">
              <div className="pricing-free-trial mt-5">
                <div className="row">
                  <div className="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                    <div className="pricing-trial-content d-flex justify-content-between">
                      <div className="text-md-left mt-3">
                        <h3 className="text-primary">
                          Actualiza tu información de pago
                        </h3>
                        <p>
                          Estamos teniendo problemas con tu información de pago
                          actual. <br />
                          ¿Quieres volver a intentar con tu tarjeta? Los
                          datos están a continuación.
                        </p>
                        <CButton
                          className="btn btn-primary mt-2 mt-lg-3"
                          onClick={() => {
                            history.push("/billing/services")
                            setActive(false);
                          }}
                        >
                          Actualizar Informacion
                        </CButton>


                      </div>
                      <CImg src={ilustracion} />
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CModalBody>
          <CModalFooter style={{ borderTop: 0 }}>
            <CButton
              onClick={() => {
                setActive(!activeModal);
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </Fragment>
      </CModal>
    </div>
  );
};
