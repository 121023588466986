import { combineReducers } from "redux";

import ManagerReducer from "src/store/reducers/ManagerReducer";
import EventReport from "./EventReport";
import SuscripcionesReducer from "./SuscripcionesReducer";
import SQLInject from "./SQLInject";

export default combineReducers({
    auth: ManagerReducer,
    suscripciones : SuscripcionesReducer,
    event : EventReport,
    sqlInject : SQLInject
  });