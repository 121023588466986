import {
  SETTING,
  SETTING_OK,
  SETTING_ERROR,
  SETTINGSAVE,
  SETTINGSAVE_OK,
  SETTINGSAVE_ERROR,
  SETTINGSTATUS,
  COMENZAR_INICIO_SESION,
  SEARCHSTATUS,
} from "../types/index";

const initialState = {
  settingCargando: true,
  settingError: false,
  SearchStatus: false,
  settingActive: false,
  exportContact: false,
  status: {
    active: 0,
    services: 0,
    renovar: 0,
    alerts: 0,
    unpaid: 0,
    load: 0,
  },
  datagraphic: {
    labels: [],
    disponible: false,
    dataGraphic: {},
    data1: [],
    data2: [],
    data3: [],
    data4: [],
  },
  settingDataError: {},
  setting: {
    empresa: {
      webmail: "",
      suscripciones: false,
    },
    usuario: {
      nombre: "",
      avatar: "",
    },
    socio : {
      jce : false
    },
    custom: {
      customized: false,
      settingconfig: {}
    },
    instancia: "",
    validar: false,
    iaevaluacion : {
      api : false,
      activo : false,
    },
    weblogin : {
      api : false,
      activo : false,
    }
  },
  settingSalvando: false,
  settinginfo: false,
  service2FA: {
    active: false,
    cargando: false,
    token: "",
  },
  FormPerfil : false,
  chatSupport: false,
  whatsapp: {
    status: "inicializando",
    substatus: "",
    process: false,
    show: false,
    qr: "",
  },
  NodeLog: false,
  ExportData: false,
  UpdateLaboral: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case 'GETSTART' :
      return {
        ...state,
        setting: {
          ...state.setting,
          custom : action.payload
        },
      };
    case SETTING:
      return {
        ...state,
        settingCargando: action.payload,
        access: false,
        gesError: false,
      };
    case SETTING_OK:
      return {
        ...state,
        settingCargando: false,
        setting: {
          ...state.setting,
          ...action.payload
        },
        settingError: false,
        settingActive: false,
      };
    case SETTING_ERROR:
      return {
        ...state,
        settingCargando: false,
        settingError: true,
        settingDataError: action.payload,
      };
    case "DATAGRAPHIC_OK":
      return {
        ...state,
        datagraphic: action.payload,
      };
    case COMENZAR_INICIO_SESION:
      return {
        ...state,
        status: {
          active: 0,
          services: 0,
          renovar: 0,
          alerts: 0,
          unpaid: 0,
          load: 0,
        },
      };
    case SETTINGSTATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SEARCHSTATUS:
      console.log("payload");
      console.log(action.payload);
      return {
        ...state,
        SearchStatus: action.payload,
      };

    case SETTINGSAVE:
      return {
        ...state,
        settingSalvando: action.payload,
        access: false,
        settingSaveError: false,
      };
    case SETTINGSAVE_OK:
      return {
        ...state,
        settingSalvando: false,
        setting: action.payload,
        settingSaveError: false,
        settingActive: false,
      };
    case SETTINGSAVE_ERROR:
      return {
        ...state,
        settingSalvando: false,
        settingSaveError: true,
        settingDataError: action.payload,
      };
    case "SETTING-INFO-OPEN":
      return {
        ...state,
        settinginfo: true,
      };
    case "SETTING-INFO-CLOSE":
      return {
        ...state,
        settinginfo: false,
      };
    case "SETTING-EDIT-OPEN":
      return {
        ...state,
        settingActive: true,
      };
    case "SETTING-EDIT-CLOSE":
      return {
        ...state,
        settingActive: false,
      };
    case "SETTING-PERFIL-OPEN" : 
      return {
        ...state,
        FormPerfil : true,
      };
    case "SETTING-PERFIL-CLOSE" : 
      return {
        ...state,
        FormPerfil : false,
      };
    case "SETTING-2FA-OPEN":
      return {
        ...state,
        service2FA: {
          ...state.service2FA,
          active: true,
          token: "",
        },
      };
    case "SETTING-2FA-CLOSE":
      return {
        ...state,
        service2FA: {
          ...state.service2FA,
          active: false,
          token: "",
        },
      };
    case "SETTING-SERVICE-2FA":
      return {
        ...state,
        service2FA: {
          ...state.service2FA,
          cargando: true,
        },
      };

    case "SETTING-SERVICE-2FA->ok":
      return {
        ...state,
        service2FA: {
          ...state.service2FA,
          cargando: false,
          nombre: action.payload.nombre,
          gsecret: action.payload.gsecret,
          gsecretqr: action.payload.gsecretqr,
        },
      };
    case "SETTING-SERVICE-2FA->Valid":
      return {
        ...state,
        service2FA: {
          ...state.service2FA,
          cargando: false,
          active: false,
          nombre: "",
          gsecret: "",
          gsecretqr: "",
        },
      };
    case "SETTING-SERVICE-2FA->error":
      return {
        ...state,
        service2FA: {
          ...state.service2FA,
          cargando: false,
          active: false,
        },
      };
    case "CHAT-ACTIVE":
      return {
        ...state,
        chatSupport: true,
      };



      


    case "EXPORTDATA-OPEN":
      return {
        ...state,
        ExportData: true,
      };
    case "EXPORTDATA-CLOSE":
      return {
        ...state,
        ExportData: false,
      };
    case "CONTACT":
      return {
        ...state,
        exportContact: !state.exportContact,
      };

    case "NodeLog":
      return {
        ...state,
        NodeLog: !state.NodeLog,
      };

    case "UPDATELABORAL":
      return {
        ...state,
        UpdateLaboral: !state.UpdateLaboral,
      };


    default:
      return state;
  }
}
