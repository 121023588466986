/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect } from "react";

export const PoliticaSuspencion = ({ display: disp }) => {
  useEffect(() => {
    setTimeout(() => {
      console.log('politica')
      eterms();
    }, 3000);
  }, []);

  const eterms = () => {
    const pri = document.getElementById("terminosSuspencion")
    if(pri){
      const print = pri.contentWindow;
      print.document.open();
      print.document.write(Suspencion);
      print.document.close();
    }

  };

  //"height: 0px; width: 0px; position: absolute"
  return (
    <iframe
      id="terminosSuspencion"
      style={
        {
          height: "100%",
          width: "100%",
          minHeight: 600,
          border: 0,
          display : disp ? "block" : "none"
        }
      }
    ></iframe>
  );
};


export const Suspencion = `
<div class="maincontent">
  <table
    width="100%"
    cellpadding="0"
    cellspacing="0"
    border="0"
    class="message"
  >
    <tbody>
      <tr>
        <td colspan="2">
          <table width="100%" cellpadding="12" cellspacing="0" border="0">
            <tbody>
              <tr>
                <td>
                  <div style="overflow: hidden">
                    <font size="-1"
                      ><div dir="ltr">
                        <br clear="all" />
                        <div>
                          <div
                            dir="ltr"
                            class="gmail_signature"
                            data-smartmail="gmail_signature"
                          >
                            <div dir="ltr">
                              <table
                                cellspacing="0"
                                align="center"
                                style="
                                  font-family: calibri;
                                  color: rgb(1, 33, 105);
                                  text-align: justify;
                                  margin-top: 5px;
                                  margin-bottom: 0px;
                                  width: 850px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td width="100%" align="center">
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <b
                                          >Política de Suspensión de Servicios y
                                          Costo de Reactivación</b
                                        ><br />
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <br />En
                                        <b>DÁNGEL SOFTWARE ELECTRONIC SRL</b>
                                        (en adelante, "Dasoft"), nos esforzamos
                                        por ofrecer un servicio de calidad a
                                        nuestros usuarios de CoopSys CoreBank.
                                        Sin embargo, es importante establecer
                                        una política de suspensión de servicios
                                        en caso de incumplimiento de pagos por
                                        parte de los usuarios. A continuación,
                                        presentamos nuestra política de
                                        suspensión de servicios y el costo de
                                        reactivación:<br /><br /><b
                                          >Suspensión de Servicios por Falta de
                                          Pago:<br /></b
                                        >a. Cuando un usuario no realiza el pago
                                        correspondiente por los servicios de
                                        CoopSys CoreBank en la fecha de
                                        vencimiento, se considerará en falta de
                                        pago.<br />b. Dasoft se reserva el
                                        derecho de suspender los servicios de
                                        CoopSys CoreBank del usuario que esté en
                                        falta de pago hasta que se regularice la
                                        situación.<br /><br /><b
                                          >Notificación de Suspensión:<br /></b
                                        >a. Dasoft notificará al usuario por
                                        correo electrónico antes de proceder a
                                        la suspensión de los servicios debido a
                                        falta de pago.<br />b. La notificación
                                        contendrá información detallada sobre el
                                        motivo de la suspensión, el saldo
                                        pendiente y las instrucciones para
                                        regularizar el pago y reactivar los
                                        servicios.<br /><br /><b
                                          >Costo de Reactivación:<br /></b
                                        >a. Para reactivar los servicios de
                                        CoopSys CoreBank después de la
                                        suspensión por falta de pago, el usuario
                                        deberá abonar un costo de
                                        reactivación.<br />b. El costo de
                                        reactivación se establece en USD$ 80
                                        dólares americanos y deberá ser pagado
                                        en su totalidad para que los servicios
                                        sean restablecidos.<br /><br /><b
                                          >Proceso de Reactivación:<br /></b
                                        >a. Una vez que el usuario haya
                                        realizado el pago del costo de
                                        reactivación, debe notificar a Dasoft
                                        por correo electrónico a la dirección
                                        especificada en la notificación de
                                        suspensión.<br />b. Dasoft procesará la
                                        reactivación de los servicios en un
                                        plazo razonable después de la
                                        confirmación del pago del costo de
                                        reactivación.<br /><br /><b
                                          >Responsabilidad del Usuario:<br /></b
                                        >a. El usuario es el único responsable
                                        de mantener su cuenta al corriente en
                                        cuanto a los pagos correspondientes a
                                        los servicios de CoopSys CoreBank.<br />b.
                                        Es responsabilidad del usuario
                                        asegurarse de que la información de pago
                                        proporcionada sea precisa y esté
                                        actualizada para evitar la suspensión de
                                        servicios.<br /><br /><b
                                          >Pérdida de Datos:<br /></b
                                        >a. El usuario debe ser consciente de
                                        que la suspensión de servicios puede
                                        resultar en la pérdida temporal de
                                        acceso a la información almacenada en
                                        CoopSys CoreBank.<br />b. Dasoft no se
                                        hace responsable de la pérdida de datos
                                        o información que pueda ocurrir durante
                                        el período de suspensión de
                                        servicios.<br /><br />Esta política de
                                        suspensión de servicios y el costo de
                                        reactivación es parte integral de los
                                        Términos y Condiciones de CoopSys
                                        CoreBank. Al aceptar los Términos y
                                        Condiciones, el usuario reconoce y
                                        acepta los términos de esta política.
                                        Dasoft se reserva el derecho de
                                        modificar esta política en cualquier
                                        momento sin previo aviso.<br />
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      ></p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="center">
                                      <p
                                        style="
                                          font-size: 16px;
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          text-align: justify;
                                        "
                                      >
                                        <b
                                          >D ANGEL SOFTWARE ELECTRONIC SRL,
                                          (DASOFT SRL)<br /></b
                                        >Matanza Adentro, Entrada de Viva No.
                                        63<br />Santiago de los Caballeros,
                                        Rep.Dom.<br /> www.dasoft.com.do <br />Tel.:&nbsp;<span
                                          style="display: inline"
                                          > (849) 937-0818
                                           </span>
                                      </p>
                                      <p
                                        style="
                                          font-size: 16px;
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 2em;
                                          text-align: justify;
                                        "
                                      >
                                        <b style="font-size: 14px"><br /></b
                                        ><b style="font-size: 14px"
                                          >R.N.C.: 1-31-05231-2</b
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </font>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
`;
