const initialState = {
  consultandoDocumentos: false,
  DonloadDocumentos: false,
  documentos: [],
  fileData: '',
  fileDonload: ''
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_DOCUMENTACION":
      return {
        ...state,
        consultandoDocumentos: true,
      };
    case "GET_DOCUMENTACION_OK":
      return {
        ...state,
        consultandoDocumentos: false,
        documentos: action.payload,
      };
    case "GET_DOCUMENTACION_ERROR":
      return {
        ...state,
        error: action.payload,
        consultandoDocumentos: false,
      };
    case "DONLOAD_DOCUMENTACION":
      return {
        ...state,
        DonloadDocumentos: true,
        fileDonload : action.payload
      };
    case "DONLOAD_DOCUMENTACION_OK":
      return {
        ...state,
        DonloadDocumentos: false,
        fileData: action.payload,
        fileDonload: ''
      };
    case "DONLOAD_DOCUMENTACION_ERROR":
      return {
        ...state,
        error: action.payload,
        DonloadDocumentos: false,
        fileDonload : ''
      };

    default:
      return state;
  }
}
