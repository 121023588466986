import {
  CButton,
  CCol,
  CFormGroup,
  CImg,
  CInput,
  CInputRadio,
  CLabel,
  CModal,
  CModalBody,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import React, { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import coopsyslogo from "../img/CoopSysLogo.png";
import { Icon } from "@iconify/react";
import Axios2 from "src/config/axios2v";
import swal from "sweetalert";

const getIcon = (type) => {
  switch (type) {
    case "mail":
      return "material-symbols:mail-outline-rounded";
    case "whatsapp":
      return "zmdi:whatsapp";
    case "sms":
      return "material-symbols:sms";
    case "Canceled":
      return "danger";
    default:
      return "primary";
  }
};
export const OTP = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);
  const { custom } = setting;
  const { show, id, otp, send } = useSelector(
    (state) => state.usuario.mensaje.otp
  );

  const type = send && send.type ? send.type : "";
  const value = send && send.value ? send.value : "";

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const buttonRef = useRef(null);

  const handleInputChange = (currentRef, nextRef, oldRef, e) => {
    const inputValue = e.target.value;
    if (inputValue.length === 1 && nextRef.current) {
      nextRef.current.focus();
    } else {
      if (inputValue.length === 0 && oldRef.current) {
        oldRef.current.focus();
      }
    }
  };

  const sendNewOTP = (e) => {
    Axios2.post(`/auth/login/otp/new`, {
      otp: id,
      id: parseInt(e.target.value),
    })
      .then(({ data }) => {
        dispatch({
          type: "INICIO_SESION_ERROR",
          payload: {
            title: `Se Requiere código de verificación de inicio de sesión`,
            text: "",
            icon: "info",
            button: "Aceptar",
            show: false,
            gAuth: false,
            otp: {
              show: true,
              otp: [],
              ...data,
            },
            login: false,
          },
        });

        setPage(0);
      })
      .catch(() => {
        swal({
          title: `OTP`,
          text: `Hubo un problema en el proceso de Envio, Favor verifique e intente mas tarde.`,
          icon: "error",
        });
      });
  };

  const VerificarOTP = async () => {
    const otpCode = `${input1Ref.current.value}${input2Ref.current.value}${input3Ref.current.value}${input4Ref.current.value}${input5Ref.current.value}${input6Ref.current.value}`;

    Axios2.post(`/auth/login/otp/valid`, {
      otp: id,
      code: otpCode,
    })
      .then(({ data }) => {
        dispatch({
          type: "INICIO_SESION_EXITO",
          payload: data,
        });
      })
      .catch((e) => {
        console.log(e);
        swal({
          title: `OTP`,
          text:
            e.response && e.response.data
              ? e.response.data
              : "Hubo un al validar informacion, intente mas tarde.",
          icon: "error",
        });
      });
  };
  return (
    <CModal
      show={show}
      onClose={() => {
        console.log("close");
      }}
      closeOnBackdrop={false}
      centered
      size=""
      style={{ background: "#f6f6f6" }}
      className="otp-modal"
    >
      <CModalBody>
        <CTabs activeTab={page} onActiveTabChange={(idx) => setPage(idx)}>
          <CTabContent>
            <CTabPane>
              <form className="otp">
                <div>
                  <CImg
                    id="logo"
                    className="imagen"
                    src={
                      custom.customized
                        ? custom.settingconfig.logoApp
                        : coopsyslogo
                    }
                  />
                </div>
                <div className="title">OTP</div>
                <div className="title">Codigo de Verificacion</div>
                <p className="message">
                  Hemos enviado un código de verificación a su {type} : {value}.
                </p>
                <div className="inputs">
                  <input
                    ref={input1Ref}
                    id="input1"
                    name="input1"
                    type="text"
                    maxLength={1}
                    onChange={(e) =>
                      handleInputChange(input1Ref, input2Ref, input1Ref, e)
                    }
                  />
                  <input
                    ref={input2Ref}
                    id="input2"
                    name="input1"
                    type="text"
                    maxLength={1}
                    onChange={(e) =>
                      handleInputChange(input2Ref, input3Ref, input1Ref, e)
                    }
                  />
                  <input
                    ref={input3Ref}
                    id="input3"
                    name="input1"
                    type="text"
                    maxLength={1}
                    onChange={(e) =>
                      handleInputChange(input3Ref, input4Ref, input2Ref, e)
                    }
                  />
                  <input
                    ref={input4Ref}
                    id="input4"
                    name="input1"
                    type="text"
                    maxLength={1}
                    onChange={(e) =>
                      handleInputChange(input4Ref, input5Ref, input3Ref, e)
                    }
                  />
                  <input
                    ref={input5Ref}
                    id="input4"
                    name="input1"
                    type="text"
                    maxLength={1}
                    onChange={(e) =>
                      handleInputChange(input5Ref, input6Ref, input4Ref, e)
                    }
                  />
                  <input
                    ref={input6Ref}
                    id="input4"
                    name="input1"
                    type="text"
                    maxLength={1}
                    onChange={(e) =>
                      handleInputChange(input6Ref, buttonRef, input5Ref, e)
                    }
                  />
                </div>
                <p className="text-center mt-4">
                  <span className="me-25">¿Tienes problemas?</span>
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(1);
                      }}
                    >
                      <span>Reenvia Codigo</span>
                    </a>
                  }
                </p>
                <CCol>
                  <CRow>
                    <CCol>
                      <CButton
                        onClick={() => {
                          setPage(0);
                          dispatch({
                            type: "OTP->CLOSE",
                          });
                        }}
                        className="action"
                        color="danger"
                        style={{ width: 120 }}
                      >
                        <Icon
                          icon="material-symbols:cancel-outline"
                          width={20}
                        />
                        Cancelar
                      </CButton>
                    </CCol>
                    <CCol style={{ justifyContent: "end", textAlign: "end" }}>
                      <CButton
                        ref={buttonRef}
                        className="action"
                        color="primary"
                        style={{ width: 120 }}
                        onClick={() => VerificarOTP()}
                      >
                        <Icon icon="whh:okcircle" width={20} />
                        Verificar
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
              </form>
            </CTabPane>
            <CTabPane>
              <div className="otp">
                <div>
                  <CImg
                    id="logo"
                    className="imagen"
                    src={
                      custom.customized
                        ? custom.settingconfig.logoApp
                        : coopsyslogo
                    }
                  />
                </div>
                <div className="title">OTP</div>
                <div className="title">Codigo de Verificacion</div>
                <p className="message">
                  Seleccione Metodo de Recepcion de su Codigo.
                </p>

                <CCol md="12">
                  {otp.map((row, index) => {
                    return (
                      <CustomSelect
                        id={index}
                        name="select"
                        type={row.type}
                        value={row.value}
                        active={row.value === send.value}
                        onChange={sendNewOTP}
                      />
                    );
                  })}
                </CCol>
                <CButton
                  onClick={() => {
                    setPage(0);
                    dispatch({
                      type: "OTP->CLOSE",
                    });
                  }}
                  className="action"
                  color="danger"
                >
                  Cancelar
                </CButton>
              </div>
            </CTabPane>
          </CTabContent>
        </CTabs>
      </CModalBody>
    </CModal>
  );
};

export const CustomSelect = ({ id, name, type, value, onChange, active }) => {
  return (
    <CFormGroup
      variant="checkbox"
      name={name}
      style={{
        padding: "7px 15px",
        border: active ? "2px solid #673de6" : "1px solid rgb(218, 217, 218)",
        margin: "9px 0 5px 0",
        borderRadius: "4px",
        background: active ? "rgb(213 211 227)" : "",
        fontWeight: active ? "bold" : "",
      }}
      onClick={() => onChange({ target: { name, value: parseInt(id) } })}
    >
      <CRow
        style={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: 10,
          marginBottom: 5,
        }}
      >
        <Fragment>
          <CCol md="1">
            {/* <CInputRadio
              className="form-check-input"
              id={id}
              name="radios"
              value="option1"
              checked={parseInt(id) === value}
            /> */}
            <Icon icon={getIcon(type)} width={20} />
          </CCol>
          <CCol md="6">
            <CLabel>{value}</CLabel>
          </CCol>
        </Fragment>
      </CRow>
    </CFormGroup>
  );
};
