import {
  COMENZAR_INICIO_SESION,
  INICIO_SESION_EXITO,
  INICIO_SESION_ERROR,
  CERRAR_SESION,
  AUTENTIFICACION404,
  CLEAR_SESION_ERROR,
} from "../types/index";

import clienteAxios from "../../config/axios";
import swal from "sweetalert";
import Axios2 from "src/config/axios2v";

// Iniciar Seccion con GoogleAuthenticator
// Comenzar inicio Sesion
export function gauthenticator(usuario) {
  return async (dispatch) => {
    dispatch({ type: "CODE_AUTHENTICATOR", payload: true });
    try {
      const peticion = await Axios2.post("/auth/login", usuario);
      // dispatch(inicioSesionExito(peticion.data));
      dispatch({ type: "CODE_AUTHENTICATOR->Success" });
      dispatch(inicioSesionExito(peticion.data));
    } catch (error) {
      dispatch({ type: "CODE_AUTHENTICATOR->Error", payload: true });

      swal({
        title: "Codigo invalido",
        text: "Codigo invalido, favor verifique e intente nuevamente.",
        button: "Aceptar",
        icon: "warning",
      });
    }
  };
}

// Comenzar inicio Sesion
export function inicioSesionReducer(usuario) {
  return async (dispatch) => {
    dispatch(comenzarLogin());
    try {
      const peticion = await Axios2.post("/auth/login", usuario);
      if (peticion.data.role === "admin") {
        dispatch({
          type: "MANAGER_SESION",
          payload: peticion.data,
        });
      } else {
        dispatch(inicioSesionExito(peticion.data));
      }
    } catch (error) {
      if (!error.response) {
        return;
      }

      // dispatch(inicioSesionError(error.response.data.msg));
      if (error.response.data === "404") {
        dispatch(
          inicioSesionError({
            title: "Institucion no Encontrada",
            text: "la institucion que intenta acceder, no hemos podido encontrar registros, favor verifique su usuario.",
            icon: "info",
            button: "Aceptar",
            show: true,
            gAuth: false,
            login: true,
            otp: { show: false, otp: [] },
          })
        );
      } else {
        if (error.response.data === "Suspendido") {
          dispatch(
            inicioSesionError({
              title: `Cuenta Suspendida`,
              text: "El perfil que intenta aceder se encuentra suspendida, favor de comunirte al departamento de soporte",
              icon: "info",
              button: "Aceptar",
              show: true,
              gAuth: false,
              login: true,
              otp: { show: false, otp: [] },
            })
          );
        } else {
          if (error.response.status === 406) {
            dispatch(
              inicioSesionError({
                title: `Se Requiere código de verificación de inicio de sesión`,
                text: "",
                icon: "info",
                button: "Aceptar",
                show: false,
                gAuth: false,
                otp: {
                  show: true,
                  otp: [],
                  ...error.response.data,
                },
                login: false,
                usuario,
              })
            );
          } else {
            if (error.response.data.includes("#")) {
              dispatch(
                inicioSesionError({
                  title: `Terminal ${error.response.data} requiere autorizacion`,
                  text: "pongase en contacto con su personal tecnico para solicitud de autorizacion para fines de acceder al sistema desde esta terminal.",
                  icon: "info",
                  button: "Aceptar",
                  show: true,
                  gAuth: true,
                  otp: {
                    show: false,
                    otp: [],
                  },
                  login: false,
                  usuario,
                })
              );
            } else {
              if (error.response.status === 511) {
                dispatch(
                  inicioSesionError({
                    title: "Error 511 - Acceso no autorizado",
                    text: error.response.data,
                    icon: "warning",
                    button: "Aceptar",
                    show: true,
                    gAuth: false,
                    otp: {
                      show: false,
                      otp: [],
                    },
                    login: true,
                  })
                );
              } else {
                console.log(error.response.status);

                dispatch(
                  inicioSesionError({
                    title: "Inicio Seccion.",
                    text: error.response.data,
                    icon:
                      parseInt(error.response.status) === 401
                        ? "error"
                        : "info",
                    button: "Aceptar",
                    show: true,
                    gAuth: false,
                    otp: {
                      show: false,
                      otp: [],
                    },
                    login: true,
                  })
                );
              }
            }
          }
        }
      }
    }
  };
}

export function LimpiarLoginError() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SESION_ERROR,
    });
  };
}

export function AuthToken(key) {
  return async (dispatch) => {
    dispatch(comenzarLogin());

    try {
      const peticion = await clienteAxios.get(`/auth/jwtLogin/?key=${key}`);
      dispatch(inicioSesionExito(peticion.data));
    } catch (error) {
      dispatch(inicioSesionError(error));
      swal({
        title: "Inicio Seccion.",
        text: error.response.data.msg,
        icon: parseInt(error.response.status) === 401 ? "error" : "info",
        button: "Aceptar",
      });
    }
  };
}

const comenzarLogin = () => ({
  type: COMENZAR_INICIO_SESION,
  payload: true,
});
const inicioSesionExito = (token) => ({
  type: INICIO_SESION_EXITO,
  payload: token,
});
const inicioSesionError = (error) => ({
  type: INICIO_SESION_ERROR,
  payload: error,
});

export function cerrarSesionAction() {
  return async (dispatch) => {
    Axios2.get("/auth/logout")
      .then(() => {
           dispatch(cerrarSesion());
       })
      .catch(() => {});
  };
}
export function cerrarSesion404Action() {
  return async (dispatch) => {
    dispatch(cerrarSesion404());
  };
}

const cerrarSesion = () => ({
  type: CERRAR_SESION,
});

const cerrarSesion404 = () => ({
  type: AUTENTIFICACION404,
});
