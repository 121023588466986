const initialState = {
  consultando: true,
  inboxCenter: [],
  current_inboxCenter: {},
  editando: false,
  refresh: false,
  token : {
    open: false,
    accessToken: ''
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "BOXCENTER-PATCH":
      return {
        ...state,
        consultando: action.payload,
        refresh: false,
      };
    case "BOXCENTER-PATCH->ok":
      return {
        ...state,
        consultando: false,
        inboxCenter: action.payload,
      };
    case "BOXCENTER-GET->ok":
      return {
        ...state,
        editando: true,
        current_inboxCenter: action.payload,
      };
    case "BOXCENTER-NEW":
      return {
        ...state,
        editando: true,
        current_sucursal: {
          id: 100+state.sucursal.length,
          nombre: "",
          telefono: "",
          direccion: "",
          pais: 1,
          departamento: 1,
          municipio: 1,
          creando: 1,
        },
      };
    case "BOXCENTER-GET-TERMINOS->ok":
      return {
        ...state,
        EditTerminos: true,
        current_producto: action.payload,
      };
    case "BOXCENTER-EDIT-TERMINOS->html":
      return {
        ...state,
        EditTerminosHtml: true,
        EditTerminos: false,
      };
    case "BOXCENTER-EDIT-TERMINOS->Close":
      return {
        ...state,
        EditTerminosHtml: false,
        EditTerminos: false,
      };
    case "BOXCENTER-POST->ok":
      return {
        ...state,
        editando: false,
        current_producto: {},
        refresh: true,
      };
    case "PROD-CHANGETERMINOS->ok":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        EditTerminosHtml: false,
        current_producto: {},
      };
    case "BOXCENTER-EDIT->Close":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    case "BOXCENTER-GET-TERMINOS->Close":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    case "BOXCENTER-GETTOKEN->ok" :
      return {
        ...state,
        token: {
          open: true,
          accessToken: action.payload.accessToken
        },
      };
    case "BOXCENTER-GETTOKEN->Close" : 
    return {
      ...state,
      token: {
        open: false,
        accessToken: ''
      },
    };
    default:
      return state;
  }
}
