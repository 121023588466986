import {
  SETTING,
  SETTING_OK,
  SETTING_ERROR,
  SETTINGSAVE,
  SETTINGSAVE_OK,
  SETTINGSAVE_ERROR,
  SETTINGSTATUS,
  SEARCHSTATUS,
} from "../types/index";

import clienteAxios from "../../config/axios";
import swal from "sweetalert";
import Axios2 from "src/config/axios2v";

// Comenzar inicio Sesion
export function getSetting() {
  return async (dispatch) => {
    dispatch(setting());

    try {
      const peticion = await Axios2.get("/setting/empresa");
      dispatch(settingOk(peticion.data));

      if(peticion.data && peticion.data.usuario ){
          if(peticion.data.usuario.otpactive===false){
            dispatch({
              type : "SETTING->OTP"
            })
          }
      }
    } catch (error) {
      dispatch(settingError(error));
    }
  };
}

export function getDataGraphic() {
  return async (dispatch) => {
    try {
      dispatch({ type: "DATAGRAPHIC" });

      const peticion = await clienteAxios.get("/setting/DataGrafic");
      const label = [];
      const data1 = [];
      const data2 = [];
      const data3 = [];
      const data4 = [];
      // eslint-disable-next-line array-callback-return
      peticion.data.map((row) => {
        label.push(row.label);
        data1.push(row.ahorro);
        data2.push(row.aportaciones);
        data3.push(row.certificado);
        data4.push(row.prestamos);
       });

      

      dispatch({
        type: "DATAGRAPHIC_OK",
        payload: {
          labels: label,
          disponible: true,
          dataGraphic : peticion.data[peticion.data.length-1],
          data1: data1,
          data2: data2,
          data3: data3,
          data4: data4,
        },
      });
    } catch (error) {
      dispatch({ type: "DATAGRAPHIC_ERROR", payload: error.response.data });
     }
  };
}

export function SettingStatus(value) {
  return async (dispatch) => {
    dispatch({
      type: SETTINGSTATUS,
      payload: value,
    });
  };
}

export function SetSearchStatusValue(value) {
  return async (dispatch) => {
    dispatch({
      type: SEARCHSTATUS,
      payload: value,
    });
  };
}

export function SaveSetting(data) {
  return async (dispatch) => {
    dispatch(settingSave());
    console.log(data)

    try {
      const peticion = await clienteAxios.post("/setting/empresa", data);
      dispatch(settingSaveOk(peticion.data));
    } catch (error) {
      dispatch(settingSaveError(error));
      swal({
        title: "Update Setting.",
        text: "Hubo un problema al intentar salvar datos.",
        icon: "warning",
        button: "Aceptar",
      });
    }
  };
}


export function SettingService2FA() {
  return async (dispatch) => {
    dispatch({type:"SETTING-SERVICE-2FA"});

    try {
      const peticion = await Axios2.get("/setting/Services2FA");
       dispatch({type:"SETTING-SERVICE-2FA->ok",payload:peticion.data});
    } catch (error) {
      dispatch({type:"SETTING-SERVICE-2FA->error",payload:error});
 
      swal({
        title: "Service 2FA.",
        text: "Hubo un problema al intentar abrir Services 2FA.",
        icon: "warning",
        button: "Aceptar",
      });
    }
  };
}
export function SettingService2FA_Valid(data) {
  return async (dispatch) => {
    dispatch({type:"SETTING-SERVICE-2FA->Verify"});

     try {
      const peticion = await Axios2.post("/setting/Services2FA",data);
      dispatch({type:"SETTING-SERVICE-2FA->Valid",payload:peticion});
      swal({
        title: "Service 2FA.",
        text: "se ha establecido 2FA como metodo de Seguridad predeterminado.",
        icon: "success",
        button: "Aceptar",
      });
    } catch (error) {
      dispatch({type:"SETTING-SERVICE-2FA->invalid",payload:error});
      swal({
        title: "Service 2FA.",
        text: "Codigo introducido es invalido, favor verifique o intente nuevamente mas tarde.",
        icon: "warning",
        button: "Aceptar",
      });
    }
  };
}

export function WhatsappQRInicializar() {
  return async (dispatch) => {
    dispatch({type:"WSQR-Inicializar"});
    try {
      const peticion = await Axios2.get("/setting/Whastapp/auth");
      dispatch({type:"WSQR-Inicializar->Success",payload:peticion.data});
      
    } catch (error) {
      dispatch({type:"WSQR-Inicializar->invalid",payload:error});
    }
  };
}
export function WhatsappMessage() {
  return async (dispatch) => {
    dispatch({type:"WSQR-MESSAGE"});
    try {
      const peticion = await Axios2.get("/setting/Whastapp/Message");
      dispatch({type:"WSQR-MESSAGE->Success",payload:peticion.data});
      
    } catch (error) {
      dispatch({type:"WSQR-MESSAGE->invalid",payload:error});
    }
  };
}

export function WhatsappQRShow() {
  return async (dispatch) => {
    dispatch({type:"WSQR"});
    try {
      const peticion = await Axios2.get("/setting/Whastapp/auth");
      dispatch({type:"WSQR->Success",payload:peticion.data});
      
    } catch (error) {
      dispatch({type:"WSQR->invalid",payload:error});
    }
  };
}
export function WhatsappQRStatus() {
  return async (dispatch) => {
    dispatch({type:"WS-STATUS"});
    try {
      const peticion = await Axios2.get("/setting/Whastapp/status");
      dispatch({type:"WS-STATUS->Success",payload:peticion.data});
      
    } catch (error) {
      dispatch({type:"WS-STATUS->invalid",payload:error});
    }
  };
}

export function ChatAsistent() {
  return async (dispatch) => {

    const loginElement = document.getElementById("c-body");

    const script = document.createElement("script");

    script.src = "https://static.coopsys.app/js/facebook.js";

    script.async = true;

    loginElement.appendChild(script);

    dispatch({type:"CHAT-ACTIVE"})

  }
}


const setting = () => ({
  type: SETTING,
  payload: true,
});
const settingOk = (token) => ({
  type: SETTING_OK,
  payload: token,
});
const settingError = (error) => ({
  type: SETTING_ERROR,
  payload: error,
});

const settingSave = () => ({
  type: SETTINGSAVE,
  payload: true,
});
const settingSaveOk = (token) => ({
  type: SETTINGSAVE_OK,
  payload: token,
});
const settingSaveError = (error) => ({
  type: SETTINGSAVE_ERROR,
  payload: error,
});
