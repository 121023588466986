export const APP = "APP";

// Login
export const COMENZAR_INICIO_SESION = "COMENZAR_INICIO_SESION";
export const INICIO_SESION_EXITO = "INICIO_SESION_EXITO";
export const INICIO_SESION_ERROR = "INICIO_SESION_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const AUTENTIFICACION404 = "AUTENTIFICACION404";
export const AUTHTOKEN = "AUTHTOKEN";
export const CLEAR_SESION_ERROR = "CLEAR_SESION_ERROR";



//Login - Validacion
export const VERIFICACION_USUARIO_INICIA = "VERIFICACION_USUARIO_INICIA";
export const VERIFICACION_USUARIO_EXITO = "VERIFICACION_USUARIO_EXITO";
export const VERIFICACION_USUARIO_ERROR = "VERIFICACION_USUARIO_ERROR";

//Consulta de Transacciones.
export const CONSULTA_SOCIO_INICIO = "CONSULTA_SOCIO_INICIO";
export const CONSULTA_SOCIO_EXITO = "CONSULTA_SOCIO_EXITO";
export const CONSULTA_SOCIO_ERROR = "CONSULTA_SOCIO_ERROR";

export const UPDATE_SOCIO_INICIO = "UPDATE_SOCIO_INICIO";
export const UPDATE_SOCIO_EXITO = "UPDATE_SOCIO_EXITO";
export const UPDATE_SOCIO_ERROR = "UPDATE_SOCIO_ERROR";

export const CREAR_CUENTA_INICIO = "CREAR_CUENTA_INICIO";
export const CREAR_CUENTA_EXITO = "CREAR_CUENTA_EXITO";
export const CREAR_CUENTA_ERROR = "CREAR_CUENTA_ERROR";
export const CREAR_CUENTA_LIMPIAR = "CREAR_CUENTA_LIMPIAR";

export const CONSULTA_LISTSOCIO_INICIO = "CONSULTA_LISTSOCIO_INICIO";
export const CONSULTA_LISTSOCIO_EXITO = "CONSULTA_LISTSOCIO_EXITO";
export const CONSULTA_LISTSOCIO_ERROR = "CONSULTA_LISTSOCIO_ERROR";

export const LIST_CUENTA_SOCIO = "LIST_CUENTA_SOCIO";
export const LIST_CUENTA_SOCIO_OK = "LIST_CUENTA_SOCIO_OK";
export const LIST_CUENTA_SOCIO_ERROR = "LIST_CUENTA_SOCIO_ERROR";

//Clientes
export const CLIENTE_CONSULTA_INICIO = "CLIENTE_CONSULTA_INICIO";
export const CLIENTE_CONSULTA_EXITOSA = "CLIENTE_CONSULTA_EXITOSA";
export const CLIENTE_CONSULTA_ERROR = "CLIENTE_CONSULTA_ERROR";

//Actualizacion
export const CLIENTE_UPDATE_INICIO = "CLIENTE_UPDATE_INICIO";
export const CLIENTE_UPDATE_EXITOSA = "CLIENTE_UPDATE_EXITOSA";
export const CLIENTE_UPDATE_ERROR = "CLIENTE_UPDATE_ERROR";

//Cargar Component de Socios
export const SOCIOS_COMPONENT_INICIA = "SOCIOS_COMPONENT_INICIA";
export const SOCIOS_COMPONENT_EXITOSA = "SOCIOS_COMPONENT_EXITOSA";
export const SOCIOS_COMPONENT_ERROR = "SOCIOS_COMPONENT_ERROR";

//Plan de Cuenas
export const CUENTA_PLAN_GET = "CUENTA_PLAN_GET";
export const CUENTA_PLAN_GET_OK = "CUENTA_PLAN_GET_OK";
export const CUENTA_PLAN_GET_ERROR = "CUENTA_PLAN_GET_ERROR";

export const CUENTA_PLAN_POST = "CUENTA_PLAN_POST";
export const CUENTA_PLAN_POST_OK = "CUENTA_PLAN_POST_OK";
export const CUENTA_PLAN_POST_ERROR = "CUENTA_PLAN_POST_ERROR";


//Estado de Cuenta Asociado
export const ESTADO_CUENTA_GET = "ESTADO_CUENTA_GET";
export const ESTADO_CUENTA_GET_OK = "ESTADO_CUENTA_GET_OK";
export const ESTADO_CUENTA_GET_ERROR = "ESTADO_CUENTA_GET_ERROR";

export const ESTADO_CUENTA_POST = "ESTADO_CUENTA_POST";
export const ESTADO_CUENTA_POST_OK = "ESTADO_CUENTA_POST_OK";
export const ESTADO_CUENTA_POST_ERROR = "ESTADO_CUENTA_POST_ERROR";



export const SOLICITUD_SUSCRIPCION_GET = "SOLICITUD_SUSCRIPCION_GET";
export const SOLICITUD_SUSCRIPCION_GET_OK = "SOLICITUD_SUSCRIPCION_GET_OK";
export const SOLICITUD_SUSCRIPCION_GET_ERROR = "SOLICITUD_SUSCRIPCION_GET_ERROR";

export const SOLICITUD_SUSCRIPCIONES_GET = "SOLICITUD_SUSCRIPCIONES_GET";
export const SOLICITUD_SUSCRIPCIONES_GET_OK = "SOLICITUD_SUSCRIPCIONES_GET_OK";
export const SOLICITUD_SUSCRIPCIONES_GET_ERROR = "SOLICITUD_SUSCRIPCIONES_GET_ERROR";


export const SOLICITUD_SUSCRIPCION_POST = "SOLICITUD_SUSCRIPCION_POST";
export const SOLICITUD_SUSCRIPCION_POST_OK = "SOLICITUD_SUSCRIPCION_POST_OK";
export const SOLICITUD_SUSCRIPCION_POST_ERROR = "SOLICITUD_SUSCRIPCION_POST_ERROR";

 
 
export const GETDEBITOCREDITO = "GETDEBITOCREDITO";
export const GETDEBITOCREDITO_OK = "GETDEBITOCREDITO_OK";
export const GETDEBITOCREDITO_ERROR = "GETDEBITOCREDITO_ERROR";

export const POSTDEBITOCREDITO = "POSTDEBITOCREDITO";
export const POSTDEBITOCREDITO_OK = "POSTDEBITOCREDITO_OK";
export const POSTDEBITOCREDITO_ERROR = "POSTDEBITOCREDITO_ERROR";

export const CONSDEBITOCREDITO = "CONSDEBITOCREDITO";
export const CONSDEBITOCREDITO_OK = "CONSDEBITOCREDITO_OK";
export const CONSDEBITOCREDITO_ERROR = "CONSDEBITOCREDITO_ERROR";



//Support Plataforma
export const SUPPORTLOADING = "SUPPORTLOADING";
export const SUPPORTLOADING_OK = "SUPPORTLOADING_OK";
export const SUPPORTLOADING_TOKEN = "SUPPORTLOADING_TOKEN";
export const SUPPORTLOADING_ERROR = "SUPPORTLOADING_ERROR";


export const SUPPORTINBOX = "SUPPORTINBOX";
export const SUPPORTINBOX_OK = "SUPPORTINBOX_OK";
export const SUPPORTINBOX_ERROR = "SUPPORTINBOX_ERROR";


export const SUPPORTINVOICE = "SUPPORTINVOICE";
export const SUPPORTINVOICE_OK = "SUPPORTINVOICE_OK";
export const SUPPORTINVOICE_ERROR = "SUPPORTINVOICE_ERROR";


export const SECURITY = "SECURITY";
export const SECURITY_OK = "SECURITY_OK";
export const SECURITY_ERROR = "SECURITY_ERROR";


export const SETTING = "SETTING";
export const SETTING_OK = "SETTING_OK";
export const SETTING_ERROR = "SETTING_ERROR";

export const SETTINGSTATUS = "SETTINGSTATUS";
export const SEARCHSTATUS = "SEARCHSTATUS";

export const SETTINGSAVE = "SETTINGSAVE";
export const SETTINGSAVE_OK = "SETTINGSAVE_OK";
export const SETTINGSAVE_ERROR = "SETTINGSAVE_ERROR";