import {
    SECURITY,
    SECURITY_OK,
    SECURITY_ERROR,
  } from "../types/index";


const initialState = {
    autorizando: true,
    gesError:false,
    gespermisos:{}
  };
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
      case SECURITY:
        return {
          ...state,
          autorizando: action.payload,
          access: false,
          gesError:false,
        };
      case SECURITY_OK:
        return {
          ...state,
          autorizando: false,
          gespermisos:action.payload.gespermisos,
          gesError:false,
        };
      case SECURITY_ERROR:
        return {
          ...state,
          autorizando: false,
          gesError: true,
          data: action.payload,
        };
  
      default:
        return state;
    }
  }
  