import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CustomGAuth } from "src/Tools/CustomGAuth";
import { useDispatch } from "react-redux";
import { inicioSesionReducer } from "src/store/actions/usuarioAction";
import { browserName, browserVersion } from "react-device-detect";
import { Spinnerblue } from "src/reusable/Spinner";
import { DisabledAutorization } from "src/reusable/Spinner";

export const GAuthLogin = () => {
  const dispatch = useDispatch();
  const [active,setActive] = useState(false)

  const logeo = (datos) => dispatch(inicioSesionReducer(datos));
  const croquets = localStorage.getItem("croquets");

  const loginSend = (data) => {
    logeo({
      type: "GoogleAuth",
      token: data.token,
      croquets: croquets,
      agent: `${browserName} ${browserVersion}`,
    });
  };

  return (
    <>
    {active && <DisabledAutorization />}
      <CustomGAuth
      onProcess={e=>{
        setActive(e)
      }}
        onSuccess={(credentialResponse) => {
          loginSend(credentialResponse);
        }}
        onError={() => {

        }}
      />
    </>
  );
};
