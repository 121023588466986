import axios from "axios";
import { configAxiosApp } from "./config";

const clienteAxios = axios.create({
  baseURL : configAxiosApp.v01
});

export const SupportAxios = axios.create({
  baseURL: "https://api.coopsys.com.do/support/1.0",
});

export const AdminAxios = axios.create({
  baseURL: "https://api.coopsys.com.do/core/1.0",
});


 


clienteAxios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const token = localStorage.getItem("accessToken");

  if (token && 401 === error.response.status) {

   
      localStorage.removeItem("accessToken");
      localStorage.removeItem("uname");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("UseData");
      window.location = '/';
 
  } else {
      return Promise.reject(error);
  }
});



AdminAxios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const token = localStorage.getItem("accessManagerToken");

  if (token && 401 === error.response.status) {

   
      localStorage.removeItem("accessManagerToken");
      localStorage.removeItem("uname");
      localStorage.removeItem("refreshManagerToken");
      localStorage.removeItem("UseManagerData");
      window.location = '/auth/login';
 
  } else {
      return Promise.reject(error);
  }
});


const Authorization = localStorage.getItem("accessManagerToken")
if(Authorization){
  AdminAxios.defaults.headers.common["Authorization"] = `Bearer ${Authorization}`;
}

export default clienteAxios;
