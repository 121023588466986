import {
  INICIO_SESION_ERROR,
  CERRAR_SESION,
  AUTENTIFICACION404,
  CLEAR_SESION_ERROR,
} from "../types/index";
import { AuthorizationManagerAuth } from "../../config/token";

const initialState = {
  cargando: false,
  error: "",
  autenticado: window.localStorage.getItem("accessManagerToken") ? true : false,
  accessToken: "",
  error401: false,
  usuario:
    window.localStorage.getItem("UseManagerData") &&
    JSON.parse(window.localStorage.getItem("UseManagerData")),
  nombre:
    window.localStorage.getItem("UseManagerData") &&
    JSON.parse(window.localStorage.getItem("UseManagerData")).nombre,
  mensaje: {
    show: false,
    gAuth: false,
    login: true,
    otp: { show: false, otp: [] },
  },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "MANAGER_SESION":
      // tokenAuth(action.payload.token);
      AuthorizationManagerAuth(action.payload.accessToken);
      window.localStorage.setItem("umname", action.payload.nombre);
      window.localStorage.setItem(
        "UseManagerData",
        JSON.stringify(action.payload)
      );
      window.localStorage.setItem(
        "accessManagerToken",
        action.payload.accessToken
      );
      window.localStorage.setItem(
        "refreshManagerToken",
        action.payload.refreshToken
      );
      return {
        ...state,
        accessToken: action.payload.accessToken,
        cargando: false,
        autenticado: true,
        error401: false,
        usuario: action.payload,
        nombre: action.payload.nombre,
      };
    case "CERRAR_SESION_MANAGER":
      window.localStorage.removeItem("accessManagerToken");
      window.localStorage.removeItem("refreshManagerToken");
      window.localStorage.removeItem("UseManagerData");
      window.localStorage.removeItem("umname");

      return {
        ...state,
        autenticado: false,
        cargando: false,
      };
    case AUTENTIFICACION404:
      window.localStorage.removeItem("accessManagerToken");
      window.localStorage.removeItem("refreshManagerToken");
      return {
        ...state,
        error401: false,
        autenticado: false,
      };
    case INICIO_SESION_ERROR:
      return {
        ...state,
        error: true,
        cargando: false,
        mensaje: action.payload,
      };
    case CLEAR_SESION_ERROR:
      return {
        ...state,
        error: false,
        cargando: false,
        mensaje: {
          ...state.mensaje,
          show: false,
          login: true,
        },
      };

    default:
      return state;
  }
}
