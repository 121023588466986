import axios from "axios";
import { useDispatch } from "react-redux";
import { configAxiosApp } from "./config";

const Axios2Report = axios.create({
  baseURL:  configAxiosApp.v02Report
});




Axios2Report.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const token = localStorage.getItem("accessToken");

  if (token && 401 === error.response.status) {


    localStorage.removeItem("accessToken");
    localStorage.removeItem("uname");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("UseData");
    window.location = '/';

  } else {
    return Promise.reject(error);
  }
});

export default Axios2Report;
