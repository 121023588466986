const initialState = {
  list: [],
  cargando: false,
  actualizando: false,
  formulario: {
    data: [],
    active: false,
  },
  recargar: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "INVOICE_CREATE":
      return {
        ...state,
        formulario: {
          data: action.payload,
          active: true,
        },
      };
    case "INVOICE_CLOSED":
      return {
        ...state,
        formulario: {
          ...state.formulario,
          active: false,
        },
      };
    case "INVOICE_GETLIST":
      return {
        ...state,
        recargar: false,
      };
    case "INVOICE_GETLIST->OK":
      return {
        ...state,
        list: action.payload,
      };
    case "INVOICE_GET->OK":
      return {
        ...state,
        actualizando: false,
        formulario: {
          data: action.payload,
          active: true,
        },
      };

    case "INVOICE_SAVE":
      return {
        ...state,
        actualizando: true,
      };
    case "INVOICE_SAVE->OK":
      return {
        ...state,
        recargar: true,
        actualizando: false,
        formulario: {
          ...state.formulario,
          active: false,
        },
      };
    case "INVOICE_SAVE->Error" :
      return {
        ...state,
        Error: action.payload,

      };
    default:
      return state;
  }
}
