const initialState = {
  consultando: true,
  sucursal: [],
  pais: [],
  provincia: [],
  municipio: [],
  current_sucursal: {},
  editando: false,
  refresh: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SUCURSAL-PATCH":
      return {
        ...state,
        consultando: action.payload,
        refresh: false,
      };
    case "SUCURSAL-PATCH->ok":
      return {
        ...state,
        consultando: false,
        sucursal: action.payload.sucursal,
        pais: action.payload.pais,
        provincia: action.payload.provincia,
        municipio: action.payload.municipio,
      };
    case "SUCURSAL-GET->ok":
      return {
        ...state,
        editando: true,
        current_sucursal: action.payload,
      };
    case "SUCURSAL-NEW":
      return {
        ...state,
        editando: true,
        current_sucursal: {
          id: 100+state.sucursal.length,
          nombre: "",
          telefono: "",
          direccion: "",
          pais: 1,
          departamento: 1,
          municipio: 1,
          creando: 1,
        },
      };
    case "SUCURSAL-GET-TERMINOS->ok":
      return {
        ...state,
        EditTerminos: true,
        current_producto: action.payload,
      };
    case "SUCURSAL-EDIT-TERMINOS->html":
      return {
        ...state,
        EditTerminosHtml: true,
        EditTerminos: false,
      };
    case "SUCURSAL-EDIT-TERMINOS->Close":
      return {
        ...state,
        EditTerminosHtml: false,
        EditTerminos: false,
      };
    case "SUCURSAL-POST->ok":
      return {
        ...state,
        editando: false,
        current_producto: {},
        refresh: true,
      };
    case "PROD-CHANGETERMINOS->ok":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        EditTerminosHtml: false,
        current_producto: {},
      };
    case "SUCURSAL-EDIT->Close":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    case "SUCURSAL-GET-TERMINOS->Close":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    default:
      return state;
  }
}
