import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CFormGroup,
  CImg,
  CInputCheckbox,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CNav,
  CNavItem,
  CRow,
} from "@coreui/react";
import moment from "moment";
import React, { Fragment } from "react";
import { useState } from "react";
import { Bookmark, User } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Axios2 from "src/config/axios2v";
import {
  terminos,
  Termminos,
} from "src/views/billing/Products/Notificaciones/terms";
import {
  terminosSupport,
  TermminosSupport,
} from "src/views/billing/Products/Notificaciones/termsSuport";
import swal from "sweetalert";
import ilustracion from "../../../img/pricing-Illustration.svg";
import qrCode from "../../../img/telegram.svg";
import { PoliticaSuspencion } from "src/views/billing/Products/Notificaciones/PoliticaSuspencion";
// import { terminos, Termminos } from "./terms";

const NavItem = ({ id,active, setActive, title, icon }) => {
  return (
    <CNavItem>
      <CButton
        style={
          active === id
            ? {
                borderColor: "#7367f0",
                boxShadow: "0 4px 18px -4px rgb(115 103 240 / 65%)",
              }
            : {}
        }
        onClick={() => setActive(id)}
      >
        {icon}
        <span className="fw-bold">{title}</span>
      </CButton>
    </CNavItem>
  );
};

export const Terminos = () => {
  const history = useHistory();
  const [summitDisabled, setSummitDisabled] = useState(true);
  const [active, setActive] = useState(0);
  const { showTerminos } = useSelector((state) => state.usuario);
  const closeAcepto = async () => {
    dispatch({ type: "POLICITA_PRIVACIDAD", payload: false });
  };
  const dispatch = useDispatch();

  console.log(active)
  return (
    <div>
      <CModal
        show={showTerminos}
        // onClose={}
        closeOnBackdrop={false}
        centered
        size="xl"
      >
        <Fragment>
          <CModalBody>
            {/* <CRow>
              <CCol>
                <div className="mt-0">
                  <h3 className="text-primary"> Terminos y Condiciones.</h3>
                </div>
              </CCol>
            </CRow> */}
            <CRow>
              <CCol>
                <CNav variant="pills">
                  
                  <NavItem
                  id={0}
                    active={active}
                    setActive={setActive}
                    title="Terminos y Condiciones."
                    icon={<Bookmark className="font-medium-3 me-50" />}
                  />
                  <NavItem
                  id={1}
                    active={active}
                    setActive={setActive}
                    title="Suspensión de Servicios y Costo de Reactivación"
                    icon={<User className="font-medium-3 me-50" />}
                  />
                  <NavItem
                  id={2}
                    active={active}
                    setActive={setActive}
                    title="Soporte"
                    icon={<User className="font-medium-3 me-50" />}
                  />
                   
                </CNav>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <Termminos display={active === 0} />
                <PoliticaSuspencion display={active === 1} />
                <TermminosSupport display={active === 2} />
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter style={{ borderTop: 0 }}>
            <CCol>
              <CButton
                className="btn btn-primary mt-2 mt-lg-3"
                onClick={() => {
                  const pri =
                    document.getElementById("ifmcontentstoprint").contentWindow;
                  pri.document.open();
                  pri.document.write(active === 0 ? terminos : terminosSupport);
                  pri.document.close();
                  setTimeout(() => {
                    pri.focus();
                    pri.print();
                  }, 600);
                }}
              >
                <CIcon name="cil-print" /> Imprimir
              </CButton>
            </CCol>
            <CCol style={{ textAlign: "end" }}>
              <CButton
                className="btn btn-primary mt-2 mt-lg-3"
                onClick={() => closeAcepto()}
              >
                Cerrar
              </CButton>
            </CCol>
          </CModalFooter>
        </Fragment>
      </CModal>
    </div>
  );
};
