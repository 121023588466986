const initialState = {
  cargando: true,
  conciliaciones: [],
  cargando_conciliacion: true,
  conciliacion: [],
  consulta:[],
  error: {},
  postear: false,
  formcrear: false,
  creado: 0,
  formedit: false,
  formData: {}
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "conciliaciones":
      return {
        ...state,
        cargando: true,
      };
    case "conciliaciones_success":
      return {
        ...state,
        cargando: false,
        conciliaciones: action.payload,
      };
    case "conciliaciones_error":
      return {
        ...state,
        cargando: false,
        conciliaciones: [],
        error: action.payload,
      };
    case "conciliaciones_postear":
      return {
        ...state,
        postear: action.payload,
      };
    case "conciliaciones_save_success":
      return {
        ...state,
        postear: false,
      };
    case "conciliaciones_get_success":
      return {
        ...state,
        cargando_conciliacion: false,
        conciliacion: action.payload,
        error: "",
      };
    case "conciliaciones_get":
      return {
        ...state,
        cargando_conciliacion: true,
      };
    case "conciliaciones_crear":
      return {
        ...state,
        formcrear: action.payload,
      };
    case "conciliaciones_create_success":
      return {
        ...state,
        creado: action.payload,
        formcrear: false,
      };
    case "conciliaciones_change_balance":
      return {
        ...state,
        formedit : true,
        formData: action.payload,
      };
    case "conciliaciones_change_close" :
      return {
        ...state,
        formedit : false,
        formData: action.payload,
      };
    case "BanTransaccion_success" :
      return {
        ...state,
        consulta: action.payload,
      };
    default:
      return state;
  }
}
