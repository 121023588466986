/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect } from "react";

export const TermminosSupport = ({ display: disp }) => {
  useEffect(() => {
    setTimeout(() => {
      eterms();
    }, 3000);
  }, []);

  const eterms = () => {
    const pri = document.getElementById("terminosycondiciones");
    if(pri){
      const print = pri.contentWindow;
      print.document.open();
      print.document.write(terminosSupport);
      print.document.close();
    }
 
  };

  //"height: 0px; width: 0px; position: absolute"
  return (
    <iframe
      id="terminosycondiciones"
      style={{
        height: "100%",
        width: "100%",
        minHeight: 600,
        border: 0,
        display: disp ? "block" : "none",
      }}
    ></iframe>
  );
};

export const terminosSupport = `
<div class="maincontent">
  <table
    width="100%"
    cellpadding="0"
    cellspacing="0"
    border="0"
    class="message"
  >
    <tbody>
      <tr>
        <td colspan="2">
          <table width="100%" cellpadding="12" cellspacing="0" border="0">
            <tbody>
              <tr>
                <td>
                  <div style="overflow: hidden">
                    <font size="-1"
                      ><div dir="ltr">
                        <br clear="all" />
                        <div>
                          <div dir="ltr" data-smartmail="gmail_signature">
                            <div dir="ltr">
                              <table
                                cellspacing="0"
                                align="center"
                                style="
                                  font-family: calibri;
                                  color: rgb(1, 33, 105);
                                  text-align: justify;
                                  margin-top: 5px;
                                  margin-bottom: 0px;
                                  width: 850px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td width="100%" align="center">
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <b>Política de Servicios de Soporte.</b>
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        La política de servicios de soporte de
                                        CoopSys CoreBank de Dasoft SRL se
                                        establece de la siguiente manera:<br />
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <b>Soporte técnico</b>: CoopSys CoreBank
                                        de Dasoft SRL proveerá soporte técnico
                                        para la utilización de la herramienta,
                                        con un tiempo de respuesta de 8 a 24
                                        horas laborables, a través del correo
                                        electrónico
                                        <a
                                          href="mailto:soporte@coopsys.com.do"
                                          target="_blank"
                                          >soporte@coopsys.com.do</a
                                        >
                                        y asignando un IDT Ticket para el
                                        seguimiento de la solicitud.<br /><br /><b
                                          >Limitaciones del soporte</b
                                        >: CoopSys CoreBank de Dasoft SRL solo
                                        ofrecerá soporte técnico para las
                                        funcionalidades de la herramienta
                                        CoopSys CoreBank. Cualquier
                                        asesoramiento o soporte relacionado con
                                        el hardware del usuario, redes, sistemas
                                        operativos, software de terceros o
                                        cualquier otro asunto fuera del alcance
                                        de la herramienta, no será
                                        responsabilidad de CoopSys CoreBank de
                                        Dasoft SRL.<br /><br /><b
                                          >Servicios de soporte adicionales</b
                                        >: CoopSys CoreBank de Dasoft SRL podrá
                                        ofrecer servicios de soporte adicionales
                                        a sus usuarios a través de acuerdos
                                        especiales, los cuales se regirán por
                                        sus propias condiciones y términos.<br /><br /><b
                                          >Actualizaciones y mejoras:</b
                                        >
                                        CoopSys CoreBank de Dasoft SRL se
                                        compromete a proporcionar
                                        actualizaciones y mejoras a la
                                        herramienta, las cuales serán
                                        comunicadas oportunamente a los
                                        usuarios. Estas actualizaciones y
                                        mejoras estarán sujetas a los términos y
                                        condiciones de este acuerdo.<br /><br /><b
                                          >Responsabilidad del usuario: </b
                                        >El usuario se compromete a proporcionar
                                        información precisa y detallada sobre
                                        los problemas técnicos que se presenten
                                        en la herramienta y a seguir las
                                        instrucciones proporcionadas por el
                                        equipo de soporte de CoopSys CoreBank de
                                        Dasoft SRL para solucionar los
                                        problemas. El usuario será el
                                        responsable de la utilización adecuada
                                        de la herramienta y de seguir las
                                        recomendaciones del equipo de soporte
                                        para evitar problemas técnicos.<br /><br /><b
                                          >Horario de soporte:</b
                                        >
                                        El horario de soporte será de lunes a
                                        viernes, de 8:00 a.m. a 5:00 p.m. hora
                                        local de la República Dominicana. Los
                                        días no laborables serán informados
                                        oportunamente a los usuarios a través de
                                        los canales de comunicación
                                        establecidos.<br /><br /><b
                                          >Nivel de soporte: </b
                                        >El nivel de soporte técnico
                                        proporcionado por CoopSys CoreBank de
                                        Dasoft SRL se establece en función de la
                                        prioridad y la complejidad del problema
                                        presentado. Los niveles de soporte
                                        pueden incluir:<br /><br /><b
                                          >a) Prioridad alta:</b
                                        >
                                        problemas críticos que afectan el
                                        funcionamiento de la herramienta y
                                        requieren una solución urgente.<br /><br /><b
                                          >b) Prioridad media:</b
                                        >
                                        problemas que afectan el uso normal de
                                        la herramienta y requieren una solución
                                        en un plazo razonable.<br /><br /><b
                                          >c) Prioridad baja:</b
                                        >
                                        problemas que no afectan
                                        significativamente el uso de la
                                        herramienta y que pueden ser resueltos
                                        en un plazo mayor.<br /><br /><b
                                          >Seguimiento de solicitudes: </b
                                        >CoopSys CoreBank de Dasoft SRL llevará
                                        un registro de todas las solicitudes de
                                        soporte técnico, incluyendo el tiempo de
                                        respuesta, la solución proporcionada y
                                        cualquier otra información relevante.
                                        Los usuarios pueden solicitar
                                        información sobre el estado de sus
                                        solicitudes a través de los canales de
                                        comunicación establecidos.<br /><br />Cambios
                                        a la política de servicios de soporte:
                                        CoopSys CoreBank de Dasoft SRL se
                                        reserva el derecho de modificar esta
                                        política de servicios de soporte en
                                        cualquier momento y sin previo aviso.
                                        Los cambios serán comunicados a los
                                        usuarios a través de los canales de
                                        comunicación establecidos. Los usuarios
                                        deben revisar periódicamente esta
                                        política para asegurarse de estar al
                                        tanto de los cambios realizados.
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <br />En resumen, CoopSys CoreBank de
                                        Dasoft SRL se compromete a ofrecer un
                                        soporte técnico de calidad y eficiente a
                                        sus usuarios, con un tiempo de respuesta
                                        de 8 a 24 horas laborables, a través del
                                        correo electrónico
                                        <a
                                          href="mailto:soporte@coopsys.com.do"
                                          target="_blank"
                                          >soporte@coopsys.com.do</a
                                        >
                                        y asignando un IDT Ticket para el
                                        seguimiento de la solicitud. Sin
                                        embargo, es importante destacar que el
                                        usuario es responsable de utilizar
                                        adecuadamente la herramienta y seguir
                                        las recomendaciones del equipo de
                                        soporte para evitar problemas técnicos.
                                        Además, cualquier asesoramiento o
                                        soporte relacionado con temas fuera del
                                        alcance de la herramienta no será
                                        responsabilidad de CoopSys CoreBank de
                                        Dasoft SRL.<br />
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        &nbsp;&nbsp;<br />
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 0em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      ></p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="center">
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <b >D ANGEL SOFTWARE ELECTRONIC SRL, (DASOFT SRL)<br /></b
                                        >Matanza Adentro, Entrada de Viva No.
                                        63<br />Santiago de los Caballeros,
                                        Rep.Dom.<br />www.dasoft.com.do<br />Tel.:
                                        <span style="display: inline"
                                          > (849) 937-0818</a
                                          > </span>
                                      </p>
                                      <p
                                        style="
                                          margin-left: 1.5em;
                                          margin-right: 1.5em;
                                          margin-bottom: 2em;
                                          font-size: 16px;
                                          text-align: justify;
                                        "
                                      >
                                        <b style="font-size: 14px"><br /></b
                                        ><b style="font-size: 14px"
                                          >R.N.C.: 1-31-05231-2</b
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                               
                            </div>
                          </div>
                        </div>
                      </div>
                    </font>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>


`;
