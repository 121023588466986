import React, { useEffect, useState } from "react";
import "./StatusBoard.css";
import { StatusBoardItem } from "./StatusBoardItem";
import { Icon } from "@iconify/react";
import moment from "moment";
import { CRow } from "@coreui/react";

export const StatusBoard = () => {
  const [Status, setStatus] = useState({
    status: "Iniciando",
    time: "",
    data: [],
  });
  const [hora, sethora] = useState("HH:mm:ss A");
  const [listServices, setListServices] = useState([]);
  const updateTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    sethora(`${hours} : ${minutes} : ${seconds}`);
  };

  const updateStatus = () => {
    const servidores = [];

    Status.map();
  };

  useEffect(() => {
    setInterval(() => updateTime(), 1000);
  }, []);

  const connectWebSocket = () => {
    const socket = new WebSocket(`ws://corebank-status-f371213f21d8.herokuapp.com/?type=lista`);
    

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "data") {
        setStatus({
          ...data.data,
          status: "CONECT",
        });
      }
    };

    socket.error = (event) => {
      console.error('WebSocket error:', event);
    };

    socket.onclose = () => {
      setStatus({
        ...Status,
        status: "DISCONECT",
      });
      setTimeout(() => connectWebSocket(), 3000);
    };

    // Cierra la conexión del socket cuando el componente se desmonta
    return () => {
      socket.close();
    };
  };
  useEffect(() => connectWebSocket(), []);

  const utcDate = moment.utc(Status.time);

  // Format the date in the user's local time zone
  const localFormattedDate = utcDate.local().format("hh : mm : ss A");
  return (
    <div className="statusBoard-container">
      <div className="statusBoard">
        <header className="header">
          <span>DataCenter</span>
          <span className="clock">
            {Status.status === "Iniciando" ? Status.status : localFormattedDate}
          </span>
        </header>
        <div className="server-list">
          {Status.data &&
            Status.data.map((row) => {
              return <StatusBoardItem data={row} />;
            })}
        </div>
      </div>
      <div className="footer">
        <p>&copy; 2024 Tu CoopSys CoreBank. Todos los derechos reservados.</p>
      </div>
    </div>
  );
};

export default StatusBoard;
