 
 
 
  const initialState = {
    geoActive: false,
    geo: [{
      name:'JUAN PEREZ',
      avatar:'https://api.coopsys.com.do/core/Storage/coopcorazon/multimedia/34c90cc271baf327551b2f003cd8edad.png',
      lat: 19.392949,
      lng:-70.667791
    },{
      name:'JOSE GASTOR',
      avatar:'https://api.coopsys.com.do/core/Storage/demo/multimedia/907556e442c5a4feee99bee76655708e.png',
      lat: 19.4118288,
      lng:-70.6685278
    }],
  };
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
      case 'GEO_LOCALIZACION':
        return {
          ...state,
          geo: action.payload,
          geoActive: true,
        };
      case 'GEO_LOCALIZACION_CLOSE':
        return {
          ...state,
          geoActive: false,
        };
      default:
        return state;
    }
  }
  