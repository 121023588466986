import {
  COMENZAR_INICIO_SESION,
  INICIO_SESION_EXITO,
  INICIO_SESION_ERROR,
  CERRAR_SESION,
  VERIFICACION_USUARIO_EXITO,
  AUTENTIFICACION404,
  AUTHTOKEN,
  CLEAR_SESION_ERROR,
} from "../types/index";
import { AuthorizationAuth } from "../../config/token";

const initialState = {
  cargando: false,
  error: "",
  autenticado: window.localStorage.getItem("accessToken") ? true : false,
  accessToken: "",
  error401: false,
  usuario: "",
  nombre: "",
  mensaje: {
    show: false,
    gAuth: false,
    login: true,
    otp: { show: false, otp: [] },
  },
  Photo: [],
  showTerminos: false,
  bypass: "",
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "MANAGER_SESION" :
      return {
        ...state,
        cargando: false,
        error: false,
      };
    case COMENZAR_INICIO_SESION:
      return {
        ...state,
        cargando: action.payload,
        error: false,
      };
    case INICIO_SESION_EXITO:
      // tokenAuth(action.payload.token);
      AuthorizationAuth(action.payload.accessToken);
      window.localStorage.setItem("uname", action.payload.nombre);
      window.localStorage.setItem("UseData", JSON.stringify(action.payload));
      window.localStorage.setItem("accessToken", action.payload.accessToken);
      window.localStorage.setItem("refreshToken", action.payload.refreshToken);
      window.localStorage.setItem("vterms", action.payload.vterms);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        cargando: false,
        autenticado: true,
        error401: false,
        usuario: action.payload.nombre,
        nombre: action.payload.nombre,
        mensaje: {
          show: false,
          gAuth: false,
          login: true,
          otp: { show: false, otp: [] },
        },
      };
    case AUTHTOKEN:
      window.localStorage.setItem("accessToken", action.payload.accessToken);
      window.localStorage.setItem("refreshToken", action.payload.refreshToken);
      window.localStorage.setItem("uname", action.payload.nombre);

      return {
        ...state,
        accessToken: action.payload.accessToken,
        cargando: false,
        autenticado: true,
        error401: false,
        usuario: action.payload.nombre,
        nombre: action.payload.nombre,
      };
    case CERRAR_SESION:
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      window.localStorage.removeItem("UseData");
      window.localStorage.removeItem("uname");

      return {
        ...state,
        autenticado: false,
        cargando: false,
      };
    case AUTENTIFICACION404:
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      return {
        ...state,
        error401: false,
        autenticado: false,
        cargando: false,
      };
    case INICIO_SESION_ERROR:
      return {
        ...state,
        error: true,
        cargando: false,
        mensaje: {
          ...state.mensaje,
          ...action.payload
        },
      };
    case CLEAR_SESION_ERROR:
      return {
        ...state,
        error: false,
        cargando: false,
        mensaje: {
          ...state.mensaje,
          show: false,
          login: true,
          otp: { show: false, otp: [] },
        },
      };
    case "OTP->CLOSE" :
      return {
        ...state,
        error: false,
        cargando: false,
        mensaje: {
          ...state.mensaje,
          show: false,
          login: true,
          otp: { show: false, otp: [] },
        },
      };
    case VERIFICACION_USUARIO_EXITO:
      return {
        ...state,
        cargando: false,
        autenticado: action.payload.confirmacion,
        usuario: action.payload.nombre,
        nombre: action.payload.nombre,
        confirmacion: state.token,
      };
    case "photo_get_success":
      return {
        ...state,
        Photo: action.payload,
      };
    case "POLICITA_PRIVACIDAD":
      return {
        ...state,
        showTerminos: action.payload,
      };
    default:
      return state;
  }
}
