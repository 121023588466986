const initialState = {
  show: false,
  customer : 0
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "Event-Report":
      return {
        ...state,
        show: action.payload,
        customer : action.customer
      };
    
    default:
      return state;
  }
}
