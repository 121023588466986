import { CButton, CImg } from "@coreui/react";
import React, { useState } from "react";
import Axios2 from "src/config/axios2v";
import { configAxiosApp } from "src/config/config";

export const CustomGAuth = ({ onSuccess, onError, onProcess }) => {
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const vincular = () => {
    const id = makeid(32);
    var win = window.open(
      `${configAxiosApp.v02}/auth/Google?id=${id}&debug=${configAxiosApp.test}`,
      "_blank",
      "titlebar=Hola,toolbar=0,location=0,menubar=0,width=450,height=700"
    );

    onProcess(true);

    var pollTimer = window.setInterval(function () {
      if (win.closed !== false) {
        // !== is required for compatibility with Opera
        window.clearInterval(pollTimer);

        Axios2.get("/auth/google/get", {
          params: {
            id,
          },
        })
          .then(({ data }) => {
            onSuccess(data);
            onProcess(false);
          })
          .catch((e) => {
            onProcess(false);
            onError(e);
          });
      }
    }, 200);

    // ventana.onload = function() {
    //     // Ya se cargó la página y se puede asignar el evento final
    //     console.log('Se c ');

    //     ventana.onunload = function() {
    //         console.log('Se cerró la ventana o el usuario cambió de página');
    //     }
    // };
  };

  return (
    <CButton
      onClick={() => vincular()}
      style={{
        color: "#787777",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "bold",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        transition: "background-color 0.3s ease",
        width:'100%'
      }}
    >
      <CImg src="https://accounts.scdn.co/sso/images/new-google-icon.72fd940a229bc94cf9484a3320b3dccb.svg" style={{marginRight:10}} />
      Iniciar Sesecion con Google
    </CButton>
  );
};
