const initialState = {
  account: [],
  formEdit: false,
  accountEdit: {},
  loading: false,
  EmptyData: [],
  search: [],
  searchActive: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "cntAccount/loading":
      return {
        ...state,
        loading: true,
      };
    case "cntAccount/get":
      return {
        ...state,
        account: action.payload,
        loading: false,
      };
    case "cntAccount/error":
      return {
        ...state,
        loading: false,
        log: {
          error: action.payload,
          response: action.payload.response.data,
        },
      };
    case "cntAccount/add":
      return {
        ...state,
        loading: false,
        formEdit: true,
        accountEdit: {
          ...action.payload,
          ecuenta: action.payload.cuenta,
        },
      };
    case "cntAccount/edit":
      return {
        ...state,
        loading: false,
        formEdit: true,
        accountEdit: {
          ...action.payload,
          ecuenta: action.payload.cuenta,
        },
      };
    case "cntAccount/cancel":
      return {
        ...state,
        loading: false,
        formEdit: false,
      };
    case "cntAccount/save":
      return {
        ...state,
        loading: false,
        formEdit: false,
      };
    case "cntEmpty/error":
      return {
        ...state,
        loading: false,
        log: {
          error: action.payload,
          response: action.payload.response.data,
        },
      };
    case "cntEmpty/edit":
      return {
        ...state,
        loading: false,
        EmptyData: action.payload,
        searchActive: false,
      };
    case "cntEmpty/Save":
      return {
        ...state,
        loading: false,
        EmptyData: {},
        document:action.payload,
        searchActive: false,
      };
    case "cntEmpty/SearchActive":
      return {
        ...state,
        loading: false,
        searchActive: action.payload,
      };
    case "cntEmpty/Search":
      return {
        ...state,
        loading: false,
        search: action.payload,
      };
    case "cntEmpty/Add":
      return {
        ...state,
        loading: false,
        EmptyData: action.payload,
        searchActive: false,
      };
    case "cntEmpty/SearchClose":
      return {
        ...state,
        loading: false,
        search: [],
      };
    default:
      return state;
  }
}
