import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useWebSocket from 'react-use-websocket';
const WS_URL = 'ws://127.0.0.1:8000';

export const StatusBoardItem = ({data}) => {
 
    // useEffect(() => {
    //   const socket = new WebSocket(`ws://127.0.0.1:8000?service=${data.service}`);
  
    //   socket.onmessage = (event) => {
    //     const data = JSON.parse(event.data);
  
    //     if (data.type === 'status') {
    //       setStatus(data.status);
    //     }
    //   };
  
    //   socket.onclose = () => {
    //     setStatus("DISCONECT"); 
    //   };
  
    //   // Cierra la conexión del socket cuando el componente se desmonta
    //   return () => {
    //     socket.close();
    //   };
    // }, []);

    // useEffect(()=>{
    //     setInterval(async()=>{
            
    //         axios.get(data.url).then(()=>{
    //             setStatus("ONLINE")
    //         }).catch(()=>{
    //             setStatus("OFFLINE")
    //         })
             
    //     },1000)
    // },[])


  return (
    <div className={data.status==="ONLINE" ? "server" : "server has-failed"}>
      <Icon icon="arcticons:services" className="fa-solid" />
      <ul className="details">
        <li>
          Service: <span className="data">{data.service}</span>
        </li>
        <li>
          Status : <span className="data signal">{data.status}</span>
        </li>
        <li>
          Locate : <span className="data">{data.hostname}</span>
        </li>
      </ul>
    </div>
  );
};
