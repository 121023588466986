const initialState = {
  active: false,
  Cart: [],
  consultando: false,
  carShopSubTotal: 0.0,
  update: false,
  create: false,
  search_estado: false,
  error: "",
  socios: [],
  perfil: [],
  estado: [],
  listCuenta: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SHOPCARD->GET":
      return {
        ...state,
        consultando: true,
      };
    case "SHOPCARD->ERROR":
      return {
        ...state,
        consultando: true,
      };
    case "SHOPCARD":
      return {
        ...state,
        consultando: false,
        Cart: action.payload,
      };
    case "SHOPCARD->open":
      return {
        ...state,
        active: true,
      };
    case "SHOPCARD->Close":
      return {
        ...state,
        active: false,
      };
    default:
      return state;
  }
}
