const initialState = {
  consultando: true,
  transacciones: [],
  pais: [],
  provincia: [],
  municipio: [],
  current_transaccion: {},
  editando: false,
  refresh: false,
  actualizar: {
    active: false,
    tipo: "",
  },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "TRANSACCIONES-PATCH":
      return {
        ...state,
        consultando: action.payload,
        refresh: false,
      };
    case "TRANSACCIONES-PATCH->ok":
      return {
        ...state,
        consultando: false,
        transacciones: action.payload.transacciones,
        tipocuenta: action.payload.tipocuenta,
      };
    case "TRANSACCIONES-GET->ok":
      return {
        ...state,
        editando: true,
        current_transaccion: action.payload,
      };
    case "TRANSACCIONES-NEW":
      return {
        ...state,
        editando: true,
        current_transaccion: action.payload,
      };
    case "TRANSACCIONES-GET-TERMINOS->ok":
      return {
        ...state,
        EditTerminos: true,
        current_producto: action.payload,
      };
    case "TRANSACCIONES-EDIT-TERMINOS->html":
      return {
        ...state,
        EditTerminosHtml: true,
        EditTerminos: false,
      };

    case "TRANSACCIONES-POST->ok":
      return {
        ...state,
        editando: false,
        current_transaccion: {},
        refresh: true,
        actualizar: action.payload,
      };
    case "PROD-CHANGETERMINOS->ok":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        EditTerminosHtml: false,
        current_producto: {},
      };
    case "TRANSACCIONES-EDIT->Close":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    case "TRANSACCIONES-GET-TERMINOS->Close":
      return {
        ...state,
        editando: false,
        EditTerminos: false,
        current_producto: {},
      };
    case "TRANSACCIONES-UPDATE->Close":
      return {
        ...state,
        actualizar: { active: false },
      };
    case "TRANS-INTEGRACION-UPDATE":
      return {
        ...state,
        actualizar: { ...state.actualizar,actualizando:true },
      };
    case "TRANS-INTEGRACION-UPDATE-OK":
      console.log(state)
      return {
        ...state,
        actualizar: { active:false,actualizando:false },
      };
    case "TRANS-INTEGRACION-UPDATE->error":
        return {
          ...state,
          actualizar: { ...state.actualizar,actualizando:false },
        };
    default:
      return state;
  }
}
