import axios from "axios";
import { configAxiosApp } from "./config";

const AxiosManager = axios.create({
  baseURL: `${configAxiosApp.v02}/manager`,
});

 


AxiosManager.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const token = localStorage.getItem("accessManagerToken");

  if (token && 401 === error.response.status) {

 
      localStorage.removeItem("accessManagerToken");
      localStorage.removeItem("umname");
      localStorage.removeItem("refreshManagerToken");
      localStorage.removeItem("UseManagerData");
      window.location = '/';
      // return Promise.reject(error);

  } else {
      return Promise.reject(error);
  }
});

export default AxiosManager;
